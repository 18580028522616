import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { HttpPostService } from '../../services/HttpPostService';
import { HttpPostServiceContextConsumer, HttpGetServiceContextConsumer } from '../../contexts/HttpServiceContext';
import { makeStyles } from '@material-ui/styles'; 
import InputAdornment from '@material-ui/core/InputAdornment'; 
import axios from 'axios'; 
import {   Redirect } from 'react-router-dom';
import qs from 'qs'; 
import {receiptUrl, payDomain} from '../../constants';
import Image
 from 'material-ui-image'
  
import { 
    Tabs, 
    InputLabel,
    MenuItem,
    Input, 
    Grid,
    Button, 
    TextField,
    RadioGroup,Col, 
    FormHelperText,
    FormControl,
    FormControlLabel,
    Radio,
    Select,
    FormLabel,
    Row,  
    Upload,  
    Card,
    CardActions,
    CardContent,
    Avatar,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TablePagination
} from '@material-ui/core' 
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress'; 
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    },
    imageSrc: {   
        width:160,
        backgroundRepeat: 'no-repeat',
        right: 0,
        top: 0,
        bottom: 0, 
        backgroundPosition: 'right center',
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
  }));

  
function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress size="9rem"   color="primary" style={{color:'rgb(220, 0, 78)'}} variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h4" component="div" color="textSecondary" style={{color:'rgb(220, 0, 78)'}}>{`${Math.round(
            props.value,
          )}% Transaction in progress`}</Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };
   
function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    
    const regex = new RegExp(`[\\?&]${name}"=([^&#]*)`);  
    // const s = new  URLSearchParams(window.location.hash)
  // const s = `http://${str[1]}`;
  // const url = new URL(s);
  if(window.location.search !== null || window.location.search !== undefined){
    const s1 = new  URLSearchParams(window.location.search) 
    console.log(window.location)
     const results = s1.get(name);
     return results === null ? '' : decodeURIComponent(results);
  }
  return '';
  };
  

  let token = '';
  let paymentAmount = 0.0;
  let paymentDesc = '';
  let mid = '';
  token = decodeURIComponent(getUrlParameter('t'));
  paymentAmount = getUrlParameter('pa');
  paymentDesc = getUrlParameter('pd');
  mid = getUrlParameter('mid');
  console.log(token, paymentAmount, paymentDesc, mid)
const getBody = (thisquery, variables) => {
    return {
      OperationName: "",
      query: thisquery,
      Variables: variables
    };
  };
 
  function RenderGrid (props) {
      const arr = props.arr;
    console.log('arr',arr);
    
   return arr.length > 0 ? arr.map(x=>{
                
                    return ( <Grid
                        container
                        justify="center"
                        alignItems="center"
                        spacing={4}
                    >
                        
                {x.length > 0 ? x.map(y=>
                         
                     y): null}
                        </Grid>)
           
            })
            : null
  }
  
  
  
  RenderGrid.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    arr: PropTypes.array.isRequired,
  };


  function RenderSelect (props) {
    const arr = props.arr; 
    const bankBanner = props.banner;
    console.log('bankBanner',bankBanner);
        return (<FormControl className={props.classes.formControl}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
        Bank
        </InputLabel>
        <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={props.bank}
            onChange={props.handleBankChange}
            displayEmpty
            className={props.classes.selectEmpty}
            >
            <MenuItem value="">
                <em>Please Select Bank</em>
            </MenuItem> 

            {arr.length > 0 ? arr[0]: null}
         </Select>
        <FormHelperText>{bankBanner}</FormHelperText>
      </FormControl>)
         
          
}

  
RenderSelect.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    arr: PropTypes.array.isRequired,
    banner: PropTypes.object.isRequired,
    classes: PropTypes.object,
    bank: PropTypes.string,
    handleBankChange: PropTypes.func
  };

const FPXPay = props => {
    const {posttoserverwithheader} = props;
    const classes = useStyles();
    const [progress, setProgress] = React.useState(0);
   
    const [totalAmount, setTotalAmount] = useState(parseFloat(paymentAmount));
    const [tok, setToken] = useState(token);
    
    const [exchangeNo, setExchangeNo] = React.useState('EX00011727');
    const [sellerID, setSellerID] = React.useState('SE00013225');
    const [merchantOrderId, setMerchantOrderId] = useState(mid);
    const [fpxTransactionID, setFpxTransactionID] = useState('-');
    const [fpxTransTime, setFpxTransactionTime] = useState('');
    const [fpxTransCur, setFpxTransactionCurrency] = useState('');
    const [buyer_email, setBuyerEmail] = useState('');
    const [ARQueryString, setARQueryString] = useState('');
    const [checkSum, setCheckSum] = useState('');
    const [AECheckSum, setAECheckSum] = useState('');
    const [BCcheckSum, setBCCheckSum] = useState('');
    const [bank, setBank] = useState('');
    const [prodDesc, setProdDesc] = useState(paymentDesc) 
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [isApproved, setIsApproved] = React.useState(false);
    const [openIBackdrop, setOpenIBackdrop] = React.useState(false);
    const [checkedTerms, setCheckTerms] = React.useState(false);
    const [btnEnabled, setBtnEnabled] = React.useState(false);
    const [startQuery, setStartQuery] = React.useState(false);
    const[activeBankList, setActiveBankList] = useState([])
    const[allBankList, setAllBankList] = useState([])
    const[inactiveBankList, setInActiveBankList] = useState([])
    const [bankBanner, setBankBanner] = useState(null);
    const [counter, setCounter] = useState(0);
    const [renderedBank,setRenderedBank] = useState([]);
    const [retailbankList] = useState(['ABB0233','ABMB0212','AMBB0209','BCBB0235','BIMB0340','BKRM0602','BMMB0341','BSN0601','HLB0224','HSBC0223','KFH0346','MB2U0227','OCBC0229','PBB0233','RHB0218','SCB0216','UOB0226','CIT0219','AGRO01','TEST0021','TEST0022','TEST0023','ABB0234','MBB0228']);
    const [retailbankListName] = useState(['Affin Bank','Alliance Bank (Personal)','AmBank','CIMB Clicks','Bank Islam','Bank Rakyat','Bank Muamalat ','BSN','Hong Leong Bank ','HSBC Bank','KFH','Maybank2U','OCBC Bank','Public Bank','RHB Bank','Standard Chartered','UOB Bank','Citibank','AGRONet','SBI Bank A','SBI Bank B','SBI Bank C','Affin B2C - Test ID','Maybank2E']);
    const [commercialbankList] = useState(['ABB0232','ABMB0213','AMBB0208','BCBB0235','BIMB0340','BKRM0602','BMMB0342','BSN0601','HLB0224','HSBC0223','KFH0346','MBB0228','OCBC0229','PBB0234','RHB0218','SCB0215','UOB0228','CIT0218','AGRO02','TEST0021','TEST0022','TEST0023']);
    
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
        return true;
    };
    
useEffect(() => {
    fillInBankList(activeBankList,inactiveBankList );
}, [activeBankList, inactiveBankList]);

useEffect(() => {
    handleQueryAE(AECheckSum); 
    console.log(checkSum)
}, [AECheckSum]);

 

useEffect(() => {
   
    
    const url =  `${payDomain}omniql`; 
    const query = `query{verifytransaction( token:"${token}",  productDesc:"${prodDesc}", mid:"${mid}",fpxTransCur:"MYR",messageType:"AR",messageToken:"01",transAmount:${totalAmount}  ) {  status,sellerTransTime, transactionCurrency ,sellerExchangeOrderNo,sellerExchangeId,sellerID,transactionAmount}}`;
   

   var query2 = JSON.stringify(getBody(query,{}));
   console.log(query2)
   async function fd(e){ e(url,query2, {'Content-Type':'application/json' },handleVerifyResponse)};
   
   fd(posttoserverwithheader);
   
   queryBESign();
  }, []);


  const handleVerifyResponse =(data)=>{
    console.log(data)
    if(data.data){
        if(data.data.data.verifytransaction.status === 'PENDING'){
            var transTime = data.data.data.verifytransaction.sellerTransTime;
            var dt = new Date(transTime);
           var transactionAmount = data.data.data.verifytransaction.transactionAmount;
            var dtFormat = [dt.getFullYear() + ('0' + (dt.getMonth() + 1)).slice(-2) + ('0' + dt.getDate()).slice(-2)+('0' + dt.getHours()).slice(-2)+('0' + dt.getMinutes()).slice(-2)+('0' + dt.getSeconds()).slice(-2)].join(''); 
            
            var cur = data.data.data.verifytransaction.transactionCurrency;
            var sellID = data.data.data.verifytransaction.sellerID;
            var sxid = data.data.data.verifytransaction.sellerExchangeId;
            setFpxTransactionTime(dtFormat);
            setTotalAmount(parseFloat(transactionAmount));
            setFpxTransactionCurrency('MYR');
            setSellerID(sellID)
            setExchangeNo(sxid)
        }
        else{
            setOpenIBackdrop(true);
            return <Redirect to='/not-found'></Redirect>
        }
    } 
  }

   
  const queryBESign = ()=>{
      
    const url =  `${payDomain}omniql`; 
    const query = `query{getbankchecksum( token:"${token}", messageToken:"01",  messageType:"BE", sellerExchangeId:"${exchangeNo}") {  message, success}}`;
   

   var query2 = JSON.stringify(getBody(query,{})); 
   async function fd(e){ e(url,query2, {'Content-Type':'application/json' },handleBCResponse)};
   
   fd(posttoserverwithheader);
  }

  
  const handleBCResponse =(data)=>{
    console.log(data)
    if(data.data){
        if(data.data.data.getbankchecksum.success){
            setBCCheckSum(data.data.data.getbankchecksum.message);
            console.log(data.data.data.getbankchecksum.message);
            queryFPXBC(data.data.data.getbankchecksum.message);
        }
    }
  }

  const queryFPXBC = (bcchecksum)=>{
    const url = 'https://uat.mepsfpx.com.my/FPXMain/RetrieveBankList';
    const _headers = {
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
          //'Access-Control-Allow-Origin':'*'
        }
    }

    if(bcchecksum === '' ){
        console.log('Message not signed. Contact administrator');
        return;
    }

    const dat = qs.stringify({
        fpx_msgType: 'BE',
        fpx_msgToken: '01',
        fpx_sellerExId: exchangeNo, 
        fpx_version:'7.0',
        fpx_checkSum:bcchecksum
    });
    
    axios.post(url, dat, _headers.headers).then(x=>{  
      //  const s1 = new  URLSearchParams(x.data) 
      //  console.log(s1.get('fpx_bankList'))
      if(x.data !== 'ERROR'){
        const qsObj = qs.parse(x.data);
        console.log(qsObj)
        const banksStr = qsObj.fpx_bankList;
        const banks = banksStr.split(',');
        let activeB = [];
        let allBank = [];
        let inactiveB = [];
        banks.map(x=>{
            
            const bb = x.split('~')
            if(bb.length > 0)
            if(bb[1] === 'A'){
                activeB.push(bb[0]);
            }
            else{
                inactiveB.push(bb[0])
            }
            allBank.push(bb[0])
        });
        setActiveBankList(activeB);
        setAllBankList(allBank);
        setInActiveBankList(inactiveB);
        console.log(activeB)
        console.log(inactiveB)
      }
    });
  }

  

  const handleSigned =(data)=>{
    console.log(data.data)
    const _headers = {
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      }

    if(data.data){
        console.log(data.data.data.updatetransactioninfo)
        if(data.data.data.updatetransactioninfo.success ){
            var checkSum2 = data.data.data.updatetransactioninfo.message;
            const url= 'https://uat.mepsfpx.com.my/FPXMain/seller2DReceiver.jsp';
            /*var formData = new FormData();
            formData.append('fpx_msgType', 'AR');
            formData.append('fpx_msgToken', '01');
            formData.append('fpx_sellerExId', exchangeNo);
            formData.append('fpx_sellerExOrderNo', mid);
            formData.append('fpx_sellerTxnTime', fpxTransTime);
            formData.append('fpx_sellerOrderNo', mid);
            formData.append('fpx_sellerId', sellerID);
            formData.append('fpx_sellerBankCode', '01');
            formData.append('fpx_txnCurrency', fpxTransCur);
            formData.append('fpx_txnAmount', totalAmount.toFixed(2));
            formData.append('fpx_buyerEmail', buyer_email);
            formData.append('fpx_checkSum', checkSum2);
            formData.append('fpx_buyerName', '');
            formData.append('fpx_buyerBankId', 'TEST0021');
            formData.append('fpx_buyerBankBranch', '');
            formData.append('fpx_buyerAccNo', '');
            formData.append('fpx_buyerId', '');
            formData.append('fpx_makerName', '');
            formData.append('fpx_buyerIban', '');
            formData.append('fpx_productDesc', prodDesc);
            formData.append('fpx_version', '7.0');
            formData.append('checkSum_String', checkSum2);*/
            const dat = qs.stringify({
                fpx_msgType: 'AR',
                fpx_msgToken: '01',
                fpx_sellerExId: exchangeNo,
                fpx_sellerExOrderNo: mid,
                fpx_sellerTxnTime: fpxTransTime,
                fpx_sellerOrderNo: mid,
                fpx_sellerId: sellerID,
                fpx_sellerBankCode: '01',
                fpx_txnCurrency: fpxTransCur,
                fpx_txnAmount: totalAmount.toFixed(2),
                fpx_buyerEmail: buyer_email,
                fpx_checkSum: checkSum2,
                fpx_buyerName: '',
                fpx_buyerBankId: bank,
                fpx_buyerBankBranch: '',
                fpx_buyerAccNo: '',
                fpx_buyerId: '',
                fpx_makerName: '',
                fpx_buyerIban: '',
                fpx_productDesc: prodDesc,
                fpx_version: '7.0',
                checkSum_String: checkSum2
            });
            
            
    async function fd(e, checksum, datStr){ 

        setARQueryString(datStr);
        setCheckSum(checksum);
        queryAEChecksum();
        e(url,dat,_headers.headers,handleSignedResponse)
        };
    
   fd(posttoserverwithheader,checkSum2, dat);
    
        }
        
    } 
  }

  const handleSignedResponse =(x)=>{ 
      console.log(x)
        var winName = '_self'; 
        var winRef = window.open('', winName);
        winRef.document.open();
        winRef.document.write(x.data);
        winRef.document.close(); 
  }
  
    const handleTermsChange = () =>{ 
        setCheckTerms(!checkedTerms)
    }

    const handleEmailChange = value =>{
        console.log(value.target.value);
    setBuyerEmail(value.target.value)
    }

    const handleToggleClose = () => {
        window.close();
    }
    const handleToggleBackdrop = () => {
        setStartQuery(true);
        setOpenBackdrop(!openBackdrop);
        onFinish();
      };
    
    const [merchantName, setMerchantName] = useState('INFINITY SUCCESS SOLUTIONS SDN BHD (1180021-V)');

    const styleRow = { background: '#eee', padding: '8px 5', marginRight:20, width:160};

    const onFinish =()=>{
        console.log('ONFINISH')
        const url =  `${payDomain}omniql`; 
    const query = `query{updatetransactioninfo( token:"${token}", buyerName:"", productDesc:"${prodDesc}",fpxTransCur:"MYR",messageType:"AR",messageToken:"01",transAmount:${totalAmount},orderNo:"${mid}", email:"${buyer_email}", bankCode:"${bank}", exchangeId:"${exchangeNo}", sellerId:"${sellerID}",  ) {  success, message }}`;
   

   var query2 = JSON.stringify(getBody(query));
   console.log(query2)
   return posttoserverwithheader(url,query2, {'Content-Type':'application/json' },handleSigned)

    }

    const onFinishFailed =()=>{

    } 

    const returnProgress = (prog) =>{
        return prog + 0.4
    }

    

    const queryAEChecksum=()=>{
        const url =  `${payDomain}omniql`; 
        const query = `query{getaechecksum( token:"${token}", buyerName:"carnegiea",  productDesc:"${prodDesc}",fpxTransCur:"MYR",messageType:"AE",messageToken:"01",transAmount:${totalAmount},orderNo:"${mid}", email:"${buyer_email}", bankCode:"${bank}", exchangeId:"${exchangeNo}", sellerId:"${sellerID}",  ) {  success, message }}`;
       
   var query2 = JSON.stringify(getBody(query));
    async function fd(e ){ 
 
        e(url,query2, {'Content-Type':'application/json' },handleReturnAEChecksum)
        };
    
   fd(posttoserverwithheader);
    
 
    }

    const handleReturnAEChecksum = (data)=>{
        console.log(data)
        if(data.data){
            if(data.data.data.getaechecksum.success){
                setAECheckSum(data.data.data.getaechecksum.message);
            }
        }
    }

    
  const queryACSign = (aeres)=>{
      
    const url =  `${payDomain}omniql`; 
    const query = `query{verifyac( token:"${token}", fpx_msgToken:"01",email:"${buyer_email}"  messageType:"AC",fpxTransCur:"MYR",transAmount:${totalAmount},  fpx_debitAuthCode:"${aeres.get('fpx_debitAuthCode')}",  fpx_debitAuthNo:"${aeres.get('fpx_debitAuthNo')}",  fpx_sellerExId:"${aeres.get('fpx_sellerExId')}",  fpx_creditAuthNo:"${aeres.get('fpx_creditAuthNo')}",  fpx_buyerName:"${aeres.get('fpx_buyerName')}",  fpx_buyerId:"${aeres.get('fpx_buyerId')}",  fpx_sellerTxnTime:"${aeres.get('fpx_sellerTxnTime')}",  fpx_sellerExOrderNo:"${aeres.get('fpx_sellerExOrderNo')}",  fpx_makerName:"${aeres.get('fpx_makerName')}",  fpx_buyerBankBranch:"${aeres.get('fpx_buyerBankBranch')}",  fpx_buyerBankId:"${aeres.get('fpx_buyerBankId')}",  fpx_creditAuthCode:"${aeres.get('fpx_creditAuthCode')}",  fpx_sellerId:"${aeres.get('fpx_sellerId')}",  fpx_fpxTxnTime:"${aeres.get('fpx_fpxTxnTime')}",  fpx_buyerIban:"${aeres.get('fpx_buyerIban')}",  fpx_sellerOrderNo:"${aeres.get('fpx_sellerOrderNo')}",  fpx_fpxTxnId:"${aeres.get('fpx_fpxTxnId')}",  fpx_checkSum:"${aeres.get('fpx_checkSum')}") {  status, transactionChecksum}}`;
   

   var query2 = JSON.stringify(getBody(query,{})); 
   async function fd(e){ e(url,query2, {'Content-Type':'application/json' },e=> handleACResponse(e, aeres))};
   
   fd(posttoserverwithheader);
  }

  const handleACResponse = (data, aeres)=>{
      if(data.data){
          if(data.data.data.verifyac){
            setStartQuery(false);
            console.log(data.data.data);
              if(data.data.data.verifyac.status === 'SUCCESS'){
                    console.log('Successfully verified');
                    var winRef = window.open(`./receipt?t=${encodeURIComponent(token)}&type=${aeres.get('fpx_msgType')}&cs=${aeres.get('fpx_checkSum')}&total=${aeres.get('fpx_txnAmount')}&tid=${aeres.get('fpx_fpxTxnId')}&soid=${aeres.get('fpx_sellerOrderNo')}`, "_self");
                  
              }
          }
      }
  }
    const handleQueryAE=(checksum)=>{ 
        console.log('handleQueryAE',checksum)
        if(startQuery ){
            const url= 'https://uat.mepsfpx.com.my/FPXMain/sellerNVPTxnStatus.jsp';
            const _headers = {
                headers: { 
                  'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
              }
              
              const dat = qs.stringify({
                fpx_msgType: 'AE',
                fpx_msgToken: '01',
                fpx_sellerExId: exchangeNo,
                fpx_sellerExOrderNo: mid,
                fpx_sellerTxnTime: fpxTransTime,
                fpx_sellerOrderNo: mid,
                fpx_sellerId: sellerID,
                fpx_sellerBankCode: '01',
                fpx_txnCurrency: fpxTransCur,
                fpx_txnAmount: totalAmount.toFixed(2),
                fpx_buyerEmail: buyer_email,
                fpx_checkSum: checksum,
                fpx_buyerName: 'carnegiea',
                fpx_buyerBankId: bank,
                fpx_buyerBankBranch: '',
                fpx_buyerAccNo: '',
                fpx_buyerId: '',
                fpx_makerName: '',
                fpx_buyerIban: '',
                fpx_productDesc: prodDesc,
                fpx_version: '7.0',
                checkSum_String: checksum
            });
            async function fd(e,query, _headers ){ 
 
                e(url,query,_headers.headers,handleAEResponse)
                };
            
           fd(posttoserverwithheader,dat, _headers);
 
        }
       
    }

    const handleAEResponse=(data)=>{
        console.log(data);
        if(data.data){
            var urlParams = new URLSearchParams(data.data);
           // var  d =JSON.parse(data.data);
            console.log(urlParams)
            var debitauth = urlParams.get('fpx_debitAuthCode');
            if(debitauth === '00'){
                queryACSign(urlParams);
            }
            
        }
    }

    React.useEffect(() => {
        if(startQuery){

            const timer = setInterval(() => {
                setProgress((prevProgress) => 
                (prevProgress >= 100 ? handleCloseBackdrop() : returnProgress(prevProgress))
                );
              }, 1000); 
              
            const timer2 = setInterval(() => 
            {
                startQuery? handleQueryAE(AECheckSum): clearInterval(timer2)
            }
                , 8000); 
              return () => {
                clearInterval(timer);
                clearInterval(timer2);
              };
        }
       
      }, [startQuery,AECheckSum]);

      const bankBan = (name,classes)=>{
          return  <div 
            style={{
                width:120,
                height:40,
                backgroundImage: `url(./resources/images/${name}_01.png)`, 
                backgroundRepeat: 'no-repeat',
                right: 0,
                top: 0,
                bottom: 0, 
                backgroundPosition: 'right center',
            }}
        ><img src={`./resources/images/${name}_01.png`}></img></div>
      }

    const onChange =event =>{
        console.log(event.target.value)
        setBank(event.target.value);
        setBtnEnabled(true);
    } 

    const handleBankChange=(e,classes) =>{
        

        if(e.target.value !== '')
        {
            setBankBanner(bankBan(e.target.value, classes));
            setBank(e.target.value);
            setBtnEnabled(true);
        }
    }
 
    const renderMenuItems = (arr, inactArr)=>{
        let bb = [];
        
        const sortedList = Object.assign([], retailbankListName); 
        sortedList.sort((a,b)=>{
            var aa = a.toUpperCase();
            var bb = b.toUpperCase();
            if (aa < bb) {
                return -1; //nameA comes first
              }
              if (aa > bb) {
                return 1; // nameB comes first
              }
              return 0;
        })
        var newBankList = [];
        for(var i in sortedList){
            //console.log(retailbankList[retailbankListName.indexOf(sortedList[i])],retailbankListName.indexOf(sortedList[i]) ,retailbankListName[retailbankListName.indexOf(sortedList[i])], sortedList[i]);
            newBankList.push(retailbankList[retailbankListName.indexOf(sortedList[i])])
        }
        //console.log(sortedList, newBankList);
        

        newBankList.map (x=>{
            if(arr.indexOf(x) !== -1)
            bb.push(<MenuItem value={x}>{sortedList[newBankList.indexOf(x)]}</MenuItem>) 
            if(inactArr.indexOf(x) !== -1)
            bb.push(<MenuItem value={x}>{`${sortedList[newBankList.indexOf(x)]} (Offline)`} </MenuItem>)
       }) 
       return bb;
    }
    const renderSelectObj = (obj, inactiveObj) =>{
        return  renderMenuItems(obj,inactiveObj).map(x=>{ return x})
            
    }

    const fillInBankList = (active, inactive)=>{ 
        let l = [];
        console.log(active)
        console.log(inactive)
         if(active.length > 0){   l.push(renderSelectObj(active, inactive))}
         
        setRenderedBank(l);
    }

    const renderAll =(ab)=>{ 
        let bb  = [];
        let childs = [];
        for(var i =0; i< ab.length; i++){ 
            if(i % 3 ===0 && i !== 0){
                if(childs.length > 0)
                    bb.push(childs)
                childs = [];
                childs.push(renderRows(ab, i));  
            }
            else{
                
                childs.push(renderRows(ab, i)); 
            }
 
        } 
        setRenderedBank(bb); 
    }

    
    const renderRows =(ab, thiscounter)=>{
      console.log(inactiveBankList)
            return   retailbankList.includes(ab[thiscounter]) && activeBankList.includes(ab[thiscounter]) ? 
                                                <Grid
                                                    item
                                                    lg={3}
                                                    md={3}
                                                    xl={3}
                                                    xs={3}
                                                    >
                                                    <div style={styleRow}> 
                                                        <FormControlLabel value={ab[thiscounter]} control={  
                                                            <span
                                                            className={classes.imageSrc} 
                                                            style={{
                                                            backgroundImage: `url(resources/images/${ab[thiscounter]}_01.png)`,  
                                                            }}
                                                        ><Radio value={ab[thiscounter]} >
                                                                
                                                            </Radio></span> } label="" >
                                                                
                                                                
                                                        </FormControlLabel>
                                                
                                                    </div> 
                                                </Grid>
                                                :
                                                /*retailbankList.includes(ab[thiscounter]) &&  inactiveBankList.includes(ab[thiscounter]) ?
                                                <Grid
                                                    item
                                                    lg={3}
                                                    md={3}
                                                    xl={3}
                                                    xs={3}
                                                    >
                                                        <div style={styleRow}> 
                                                    <FormControlLabel value={ab[thiscounter]} control={  
                                                    <span
                                                    className={classes.imageSrc} 
                                                    style={{
                                                        backgroundImage: `url(resources/images/${ab[thiscounter]}_01.png)`,  
                                                    }}
                                                    ><Radio value={ab[thiscounter]} >
                                                            
                                                        </Radio></span> } label="(Offline)" >
                                                            
                                                            
                                                    </FormControlLabel>
                                                        </div> 
                                                </Grid>
                                                :*/ <Grid
                                                item
                                                lg={3}
                                                md={3}
                                                xl={3}
                                                xs={3}
                                                >
                                                    <div style={styleRow}> 
                                                <FormControlLabel value={ab[thiscounter]} control={  
                                                <span
                                                className={classes.imageSrc} 
                                                style={{
                                                    backgroundImage: `url(resources/images/${ab[thiscounter]}_01.png)`,  
                                                }}
                                                ><Radio value={ab[thiscounter]} disabled >
                                                        
                                                    </Radio></span> } label="(Offline)" >
                                                        
                                                        
                                                </FormControlLabel>
                                                    </div> 
                                            </Grid>
       
        
    }

    // <span className="pager-align pager_img nav nav-pills pull-right"></span>

    return (
        
<div className="container" style={{margin:"0 auto", alignContent:'center', alignItems:'center',width:800}}>
<div>
      <Backdrop className={classes.backdrop} open={openBackdrop} >
      <CircularProgressWithLabel value={progress} />
      </Backdrop>
      
      <Backdrop className={classes.backdrop} open={openIBackdrop} >
        Invalid
      </Backdrop>
    </div>
    <div className="header">
        <span className="banner_img banner-alignment img-responsive" title={`Merchant Name: ${merchantName}`} >
        <img src='./images/logos/fpx_banner.png'/>
        </span>
    </div>
   
    <form
                
    layout="vertical"
        
    
    onSubmit={onFinish} 
    className="mb-4"  
    >  
		<Input type="hidden" name="winstatus" value="2" />
		
		<div className="panel panel-default">
			<div className="panel-heading">
				<h3 className="panel-title"><strong>Payment Details</strong></h3>
			</div>
			
			<div className="panel-body">	
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td>Merchant Name</td>
                            <td>{merchantName}</td>
                        </tr>
                        <tr>
                            <td>Merchant Order No</td>
                            <td>{merchantOrderId}</td>
                        </tr>
                        <tr>
                            <td>FPX Transaction ID</td>
                            <td>{fpxTransactionID}</td>
                        </tr>
                        <tr>					
                            <td>Product Description</td>
                            <td>{prodDesc}</td>
                        </tr>
                        <tr>					
                            <td>Amount</td>
                            <td>{totalAmount.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>			
			</div>
		</div>

		<div className="panel panel-default"> 
            <div className="panel-body">	
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td>Bank</td>
                            <td><RenderSelect arr={renderedBank} banner={bankBanner} classes={classes} handleBankChange={e=> handleBankChange(e, classes)} bank={bank}/></td>
                        </tr> 
                    </tbody>
                </table>			
			</div> 
		</div>

		<div className="panel panel-default">
			<div className="panel-heading">
                
				<div id="emailDiv" className="input-group has-error">
                    
					<span className="input-group-addon" style={{color:'#fff' }}>				
						
                    <TextField
                        fullWidth
                        helperText="Please specify your email"
                        label="Email"
                        margin="dense"
                        name="buyer_email"
                        onChange={handleEmailChange}
                        required  
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span className="glyphicon glyphicon-envelope" aria-hidden="true"></span>
                              </InputAdornment>
                            ),
                          }}
                        value={buyer_email}
                        variant="outlined"
                    />
					</span>	
					
				</div>				
				<span id="alertBlock" className="text-danger"></span>
			</div>
		</div>		


		
		<div className="panel panel-default">
			<div className="panel-heading">
                <h3 className="panel-title">
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={checkedTerms}
                        onChange={handleTermsChange}
                        name="checkedTerms"
                        color="primary"
                    />
                    }
                    label=" I agree with the "
                />
                <a href="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" target='_blank'> FPX's Terms and Conditions</a>
                 
                </h3>
			</div>
			<div className="panel-body">
                <p>			
                </p>
                <h4>	
                    <em>
                        <small>				
                            <ul>
                                <li>
                                    Please disable your pop-up blocker. For more information, please <a href="https://support.google.com/chrome/answer/95472?co=GENIE.Platform%3DDesktop&amp;hl=en"  target="_blank"> click here</a>
                                </li>
                                <li>
                                    On some browser, you may see the Transaction Response displayed in two&nbsp;(2) tabs. This will not affect your transaction. For more information, please <a href="http://www.myclear.org.my/personal-fpx.html" target="_blank"> click here </a>
                                </li>
                                <li>
                                    For FPX operation hour, please <a href='https://www.mepsfpx.com.my/FPXMain/Html/faq.html' target="_blank"> click here </a>
                                </li>
                                <li>
                                    Please do not click on browser&apos;s back button, refresh or close this page.
                                </li>
                            </ul>
                        </small>
                    </em>
                </h4>
			</div>	
		</div> 
            <span data-toggle="tooltip" data-placement="top" title="" data-original-title="Please select a bank to continue">
                
                <Button variant="outlined" color="primary" className="btn btn-primary" onSubmit={onFinish} onClick={handleToggleBackdrop} disabled={!btnEnabled} >
                    Agree and Continue
                </Button>
               
            </span> 
                <Button variant="outlined" color="primary" className="btn btn-primary"  onClick={handleToggleClose} >
                    Back
                </Button>
              
			<span className="pull-right">
                <span className="verisign_img footerimg-align img-responsive" title="Click to Verify - This site chose VeriSign SSL for secure e-commerce and confidential communications." style={{cursor: "pointer"}}/* onClick="openVerisign();" */>

                </span>
                <span className="myclogo_img footerfpximg-align img-responsive" title="Click to go  MyClear home Page" style={{cursor: "pointer"}}/* onClick="openMEPS()" */>

                </span>
			</span> 
		
		<div id="footer">
            <div className="footer">
                <h6>
                    
                </h6>
            </div>	
		</div>
    </form>
</div>
    )
}
const HOCFpxPay =() =>{
    return ( 
            <HttpPostServiceContextConsumer> 
                {({ 
                    postToServerWithHeader  })=>
                (
                    
                        <FPXPay posttoserverwithheader ={postToServerWithHeader}/>
                    
            
            )}
        </HttpPostServiceContextConsumer> 
    );

    }
   

  
const HOCFpxMain  = () => {
    return (
        
    <HttpPostService    > 
        <HOCFpxPay /> 
    </HttpPostService>
    );
  } 

  
export default HOCFpxMain;

 /*  
 
		<Input type="hidden" name="fpx_token" value="3JK2WUNNA0A9915687LNWA8J74O1I85H" />
		<Input type="hidden" name="errcode" value="TXN_BUYER_CANCEL" />
		<Input type="hidden" name="buyer_id" value="defaultMail@mailDomain.com" />
		<Input type="hidden" name="ssval1" value="Q6Fxf7zLclVt5hTk5MgNT4ysyG1ntTf112sTFLL6wxBYjkc2Gsk0!-123819762!-340395063!1606103816607" />
		<Input type="hidden" name="bankinfo" value="****" />
		<Input type="hidden" id="XYZ" name="XYZ2" value="****" />
	
		<Input type="hidden" name="as_fid" value="1afd9c2fea63f9e7169cda86caf386de48523304" /> 
 <Input type="button" id="btnSubmit"  value="Agree and Continue" style={{cursor:"pointer"}} onClick="return ok();" onkeydown="return blockEnter(event)"  /> 
 <Input id="email_txt" name="email_txt" title="Please enter your email address"maxlength="50" placeholder="Email" value="" type="email" />
 */
    /*
		<div className="panel panel-default">
			<div className="panel-heading">
				Enter E-Mail address here to receive transaction status <small><em>(optional)</em></small>
				<input type="text" id="email_txt"  name="email_txt" value='' maxlength="40" size="25" />
			</div>
        </div>
        


        <p className="text-center"> |&nbsp;<a style="cursor: pointer;" onClick="showpage('faq')">FAQ</a>&nbsp;|&nbsp;<a style="cursor: pointer;" onClick="showpage('join')">Merchant Program</a>&nbsp;|&nbsp;<a style="cursor: pointer;" onClick="showpage('contact')">Contact Us</a>&nbsp;|&nbsp;<a style="cursor: pointer;" onClick="showpage('popup')">Pop-up Blocker Settings</a>&nbsp;|&nbsp;<a style="cursor: pointer;" onClick="javascript:window.open('t_and_c_link.jsp', 'cont', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=no,titlebar=no, width=550, height=500');">Terms and Conditions</a>&nbsp;|&nbsp;<a style="cursor: pointer;" onClick="showpage('privacy')">Privacy Policy</a>&nbsp;|&nbsp;<a style="cursor: pointer;" onClick="javascript:window.open('disclaimer.jsp', 'cont', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=no,titlebar=no, width=550, height=500');">Disclaimer</a>&nbsp;|</p>
		<p className="text-center">Malaysian Electronic Clearing Corporation Sdn Bhd 836743-D | <a target="_blank" href="http://www.myclear.org.my/"> http://www.myclear.org.my </a></p>
		<p className="text-center">© Malaysian Electronic Clearing Corporation Sdn Bhd . All Rights Reserved.</p>
		<p className="text-center">FPX V5.0</p>
	*/

    /*
<div className="panel panel-default">
			<div className="panel-heading">
				<h3 className="panel-title"><strong>Corporate Banking</strong></h3>
			</div>
			<div className="panel-body">	
				<div className="row">	
				
                    <div className="col-sm-6 col-md-4 col-lg-4">
                        <p>
                        </p>
                        <div className="input-group">

                            <span className="input-group-addon">												
                                <Radio value='MBB0228'>
                                    <Image width={120}
                                    height={39}
                                        src="resources/images/MBB0228_01.png"
                                        />
                                </Radio> 
                            </span>	

                        </div>
                        <p></p>
                    </div>	
				</div>
			</div>
		</div>		

        
                    activeBankList.map ( x=> {
 
                        <Grid
                        container
                        justify="center"
                        alignItems="center"
                        spacing={4}
                        > 
                             
                            bankList.includes(x) ? 
                                <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    xl={3}
                                    xs={3}
                                    >
                                        <div style={styleRow}>
                                                
                                            <FormControlLabel value={x} control={  
                                                <span
                                                className={classes.imageSrc} 
                                                style={{
                                                backgroundImage: `url(resources/images/${x}_01.png)`,  
                                                }}
                                            ><Radio value={x} >
                                                    
                                                </Radio></span> } label="" >
                                                    
                                                    
                                            </FormControlLabel>
                                    
                                        </div>
                                    </Grid>
                                    :
                                    <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    xl={3}
                                    xs={3}
                                    >
                                        <div style={styleRow}>
                                    <FormControlLabel value={x} control={  
                                    <span
                                    className={classes.imageSrc} 
                                    style={{
                                        backgroundImage: `url(resources/images/${x}_01.png)`,  
                                    }}
                                    ><Radio value={x+ ' (Offline)'} >
                                            
                                        </Radio></span> } label="" >
                                            
                                            
                                    </FormControlLabel>
                                        </div>
                                    </Grid>
                                    </Grid>
                                    :

                    })
                            

              
                            
                         
                 <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                        <FormControlLabel value="ABMB0212" control={ 
                        <span
                         className={classes.imageSrc} 
                         style={{
                           backgroundImage: `url(resources/images/ABMB0212_01.png)`,  
                         }}
                       > <Radio value='ABMB0212'>
                                </Radio>
                                </span>} label="" /> 
                        </div>
                    </Grid>
                    <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                        <FormControlLabel value="AMBB0209" control={
                            <span
                            className={classes.imageSrc} 
                            style={{
                              backgroundImage: `url(resources/images/AMBB0209_01.png)`,  
                            }}
                          >
                            <Radio value='AMBB0209'/></span>} label="" /> 
                             
                        </div>
                    </Grid>
                </Grid>
                <Grid
                container
                justify="center"
                alignItems="center"
                spacing={4}
                > 
                
                <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                            
                        <FormControlLabel value="BCBB0235" control={
                            <span
                            className={classes.imageSrc} 
                            style={{
                              backgroundImage: `url(resources/images/BCBB0235_01.png)`,  
                            }}
                          >
                               <Radio value='BCBB0235'>
                                    
                                </Radio>
                                </span>} label="" /> 
                         
                        </div>
                    </Grid >
                    <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                            
                        <FormControlLabel value="BIMB0340" control={ 
                            <span
                                className={classes.imageSrc} 
                                style={{
                                backgroundImage: `url(resources/images/BIMB0340_01.png)`,  
                                }}
                            >
                           <Radio value='BIMB0340'>
                                    
                                </Radio>
                                </span>} label="" /> 
                          
                        </div>
                    </Grid>
                    <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                            
                            <FormControlLabel value="BKRM0602" control={ <span
                                className={classes.imageSrc} 
                                style={{
                                backgroundImage: `url(resources/images/BKRM0602_01.png)`,  
                                }}
                            >
                                <Radio value='BKRM0602'>
                                        
                                    </Radio>
                                    </span>
                                } label="" /> 
                              
                             
                        </div>
                    </Grid>
                </Grid>
                <Grid
                container
                justify="center"
                alignItems="center"
                spacing={4}
                > 
                    <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                            
                            <FormControlLabel value="BMMB0341" control={ 
                            <span
                                className={classes.imageSrc} 
                                style={{
                                backgroundImage: `url(resources/images/BMMB0341_01.png)`,  
                                }}
                            >
                            <Radio value='BMMB0341'>

                                        
                                    </Radio>
                                    </span>} label="" /> 
                             
                        </div>
                    </Grid>
                    <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                            
                            <FormControlLabel value="BSN0601" control={ <span
                                className={classes.imageSrc} 
                                style={{
                                backgroundImage: `url(resources/images/BSN0601_01.png)`,  
                                }}
                            > <Radio value='BSN0601'>
                                         
                                    </Radio>
                                </span>} label="" /> 
                             
                        </div>
                    </Grid>
                    <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                            
                            <FormControlLabel value="HLB0224" control={ 
                            <span
                            className={classes.imageSrc} 
                            style={{
                            backgroundImage: `url(resources/images/HLB0224_01.png)`,  
                            }}
                        > 
                        <Radio value='HLB0224'>
                                        
                                    </Radio>
                                    </span>} label="" /> 
                             
                             
                        </div>
                    </Grid>
                </Grid>
                <Grid
                container
                justify="center"
                alignItems="center"
                spacing={4}
                > 
                    <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                            
                            <FormControlLabel value="HSBC0223" control={ 
                            <span
                            className={classes.imageSrc} 
                            style={{
                            backgroundImage: `url(resources/images/HSBC0223_01.png)`,  
                            }}
                        > 
                        <Radio value='HSBC0223'> 
                                    </Radio>
                                    </span>} label="" /> 
                             
                             
                            
                         
                        </div>
                    </Grid>
                    <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                            
                            <FormControlLabel value="KFH0346" control={ 
                             <span
                             className={classes.imageSrc} 
                             style={{
                             backgroundImage: `url(resources/images/KFH0346_01.png)`,  
                             }}
                         > 
                         <Radio value='KFH0346'>
                                        
                                    </Radio>
                                    </span>} label="" /> 
                              
                        </div>
                    </Grid>
                    <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                            
                        <FormControlLabel value="MB2U0227" control={
                             <span
                             className={classes.imageSrc} 
                             style={{
                             backgroundImage: `url(resources/images/MB2U0227_01.png)`,  
                             }}
                         > 
                          <Radio value='MB2U0227'>
                                         
                                    </Radio>
                                    </span>} label="" /> 
                               
                        </div>
                    </Grid>
                </Grid>
                <Grid
            justify="center"
            alignItems="center"
                container
                spacing={4}
                > 
                   
                   <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                            
                            
                        <FormControlLabel value="OCBC0229" control={ 
                        <span
                             className={classes.imageSrc} 
                             style={{
                             backgroundImage: `url(resources/images/OCBC0229_01.png)`,  
                             }}
                         > 
                         <Radio value='OCBC0229'>
                                        
                                    </Radio>
                                    </span>} label="" /> 
                                
                        </div>
                    </Grid>
                    <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                            
                        <FormControlLabel value="PBB0233" control={ 
                             <span
                             className={classes.imageSrc} 
                             style={{
                             backgroundImage: `url(resources/images/PBB0233_01.png)`,  
                             }}
                         > 
                         <Radio value='PBB0233'>
                                        
                                    </Radio>
                                    </span>} label="" /> 
                                 
                        </div>
                    </Grid>
                    <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                            
                        <FormControlLabel value="RHB0218" control={ 
                         <span
                         className={classes.imageSrc} 
                         style={{
                         backgroundImage: `url(resources/images/RHB0218_01.png)`,  
                         }}
                     > 
                     <Radio value='RHB0218'>
                                        
                                    </Radio>
                                    </span>} label="" /> 
                                  
                        </div>
                    </Grid>
                </Grid>
                <Grid
                container
                justify="center"
                alignItems="center"
                spacing={4}
                > 
                   <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={3}
                    >
                        <div style={styleRow}>
                            
                            
                        <FormControlLabel value="SCB0216" control={ 
                        <span
                         className={classes.imageSrc} 
                         style={{
                         backgroundImage: `url(resources/images/SCB0216_01.png)`,  
                         }}
                     > 
                     <Radio value='SCB0216'> 
                                    </Radio>
                                    </span>} label="" /> 
                                   
                        </div>
                    </Grid>
                    <Grid
                    item
                    lg={6}
                    md={6}
                    xl={6}
                    xs={6}
                    >
                        <div style={styleRow}>
                            
                        <FormControlLabel value="UOB0226" control={ 
                        <span
                        className={classes.imageSrc} 
                        style={{
                        backgroundImage: `url(resources/images/UOB0226_01.png)`,  
                        }}
                    > 
                    <Radio value='UOB0226'>
                                        
                                    </Radio>
                                    </span>} label="" /> 
                                    
                        </div>
                    </Grid>
                </Grid>
            </Grid>

    */

/*

 <RadioGroup aria-label="Select Bank" name="bank" value={bank} onChange={onChange}>
      
        <div className="panel panel-default">
            <div className="panel-heading">

                <h3 className="panel-title"><strong>Retail Banking</ strong></h3>

            </div>
            <RenderGrid arr={renderedBank} />
                
        </div>

		
			
		
        </RadioGroup>
        */