import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import  NewAccountForm   from './components';
import { HttpPostServiceContextConsumer } from '../../contexts/HttpServiceContext';

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const NewAccount = () => {
  const classes = useStyles();

  return (
    
<HttpPostServiceContextConsumer> 
    {({ httpPostContext,
        setHttpPostContext,
        postToServerWithHeader  })=>
    (
      <React.Fragment>
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <NewAccountForm postToServer={postToServerWithHeader} sethttppostcontext={setHttpPostContext} httppostcontext={httpPostContext}/>
        </Grid>
      </Grid>
    </div>
    </React.Fragment>  
    )}
</HttpPostServiceContextConsumer>
  );
};

export default NewAccount;
