import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { HttpPostServiceContextConsumer, HttpGetServiceContextConsumer } from './contexts/HttpServiceContext';

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  SetProduct as SetProduct,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  LoginMain as SignInView,
  NotFound as NotFoundView,
  Order as OrderView,
  NewAccount as NewAccountView,
  SignOut as SignOut,
  OrderList as OrderListView,
  UserNetworth as UserNetworth,
  TellerOrderPendingList as TellerOrderPendingListView,
  TellerOrderInProcessList as TellerOrderInProcessListView,
  UserTree as UserTreeView,
  FPXPay as FPXView,
  Receipt as ReceiptView
} from './views';
import { SessionContextProvider } from "./contexts/SessionContext";

import RequireAuth from "./RequireAuth.js";
import { DownlineRate } from './views/ProductList/components/DownlineRate';
const Routes = () => {
  return (
    <SessionContextProvider>
      
    <Switch>
      
      <Redirect
        exact
        from="/"
        to="/sign-in"
      /> 
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={FPXView}
        exact
        layout={MinimalLayout}
        path="/pay"
      />
      <RouteWithLayout
        component={ReceiptView}
        exact
        layout={MinimalLayout}
        path="/receipt"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>  
    </SessionContextProvider>
  );
};

export default Routes;
