import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles'; 
import 'react-perfect-scrollbar/dist/css/styles.css';
import {domainUrl} from '../../../../constants';
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
} from 'react-table';
import {
  Card,
  CardActions,
  CardContent,
  Modal ,
  Button,
  CardHeader,
  Divider,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import { getInitials } from '../../../../helpers';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow:'auto'
  };
}
const useStyles = makeStyles((theme :any)=> ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));

function TTable({ classes, columns,className, data,...rest }) {
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
  )


    return (
      <Card
           {...rest}
           className={clsx(classes.root, className)}
         >
           <CardContent className={classes.content}>
             <PerfectScrollbar>
               <div className={classes.inner}>
               
               <Table {...getTableProps()}>
                   <TableHead>
                         {headerGroups.map(headerGroup => (
                     <TableRow {...headerGroup.getHeaderGroupProps()}>
                       {headerGroup.headers.map(column => ( 
                         <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                           {column.render('Header')}
                           <span>
                             {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                           </span>
                         </TableCell>
                       ))}
                     </TableRow>
                   ))}
                      
                   </TableHead>
                   <TableBody  {...getTableBodyProps()}>
                     {page.map((row, i) => {
                       prepareRow(row)
                       return (
                         <TableRow 
                         className={classes.tableRow}
                         hover
                          {...row.getRowProps()}>
                           {row.cells.map(cell => {
                             return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                           })}
                         </TableRow>
                       )
                     })}
                   
                   </TableBody>
                 </Table>
     
               </div>
             </PerfectScrollbar>
           </CardContent>
           <CardActions className={classes.actions}>
             <div className="pagination">
               <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                 {'<<'}
               </button>{' '}
               <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                 {'<'}
               </button>{' '}
               <button onClick={() => nextPage()} disabled={!canNextPage}>
                 {'>'}
               </button>{' '}
               <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                 {'>>'}
               </button>{' '}
               <span>
                 Page{' '}
                 <strong>
                   {pageIndex + 1} of {pageOptions.length}
                 </strong>{' '}
               </span>
               <span>
                 | Go to page:{' '}
                 <input
                   type="number"
                   defaultValue={pageIndex + 1}
                   onChange={e => {
                     const page = e.target.value ? Number(e.target.value) - 1 : 0
                     gotoPage(page)
                   }}
                   style={{ width: '100px' }}
                 />
               </span>{' '}
               <select
                 value={pageSize}
                 onChange={e => {
                   setPageSize(Number(e.target.value))
                 }}
               >
                 {[10, 20, 30, 40, 50].map(pageSize => (
                   <option key={pageSize} value={pageSize}>
                     Show {pageSize}
                   </option>
                 ))}
               </select>
             </div>
           </CardActions>
         </Card>
         )
}

const OrdersTable = props => {
  const { className, orders,handleSubmitOrder, 
    downlines, statusCallBack, handleRetractOrder, handleGetBanks,handleGetRates,obtainRate,banks,  ...rest } = props;
  const [modalStyle] = React.useState(getModalStyle);

  const [values, setValues] = useState({
    recipient_contact: '010', 
    bank_name: 0,
    bank_account: '12345',
    bank_fee_idr:  6000,
    my_fee_myr: 5,
    my_amount:0,
    my_rate_idr: 2300,
    my_obtainedrate_idr: 2300,
    my_obtainedexpress: 2300,
    is_express: false, 
    sender_phone: '',
    rate_earning: 0,
    fee_earning: 0,
    my_total:0,
    my_total_idr:0,
  });
 
  const classes = useStyles();
  const [responseMessage,setResponseMessage]= useState('');
  const [responseTitle,setResponseTitle]= useState('');
  const [editCode,setEditCode]= useState('');

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openModal, setModalOpen] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [theBanks, setBanks] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedOrder, setSelectedOrder]=useState(null);
  
  const handleToRetract= (code)=>{
    handleRetractOrder(code);
    handleDialogClose();
  }
  const columns=React.useMemo(
    () => [
    {
      "Header": "Order Code",
      "accessor": "orderCode",
      sortType: 'basic'
    },{
      "Header": "Date Time",
      "accessor": "doneOn",
      sortType: 'basic',
      Cell: props=>  {return moment(props.value).format('DD/MM/YYYY')}
    },{
        "Header": "Sender",
        "accessor": "senderContact",
        sortType: 'basic'
    },{
        "Header": "Recipient Name",
        "accessor": "recipientAccountName",
        sortType: 'basic'
    },{
        "Header": "Account",
        "accessor": "recipeintAccountNo",
        sortType: 'basic'
    },{
        "Header": "Bank",
        "accessor": "bankName",
        sortType: 'basic'
    },{
      "Header": "MYR",
      "accessor": "amountBase",
      sortType: 'basic'
    },{
      "Header": "IDR",
      "accessor": "amountExchange",
      sortType: 'basic'
    },{
      "Header": "Rate",
      "accessor": "orderRate",
      sortType: 'basic',

    },{
        "Header": "Obtain Rate",
        "accessor": "obtainedRate",
        sortType: 'basic',
  
    },{
        "Header": "Fee",
        "accessor": "orderFee",
        sortType: 'basic',
  
    },{
        "Header": "Obtain Fee",
        "accessor": "obtainedFee",
        sortType: 'basic',
  
    },{
      "Header": "Order Status",
      "accessor": "orderStatus",
      sortType: 'basic',

  },{
        "Header": "Agent",
        "accessor": "agentUsername",
        sortType: 'basic',
  
    },{
      "Header": "Action", 
      
      Cell: props =>{  
        var dd = [];
        downlines.map(d => dd.push(d.username));; 
        return (props.row.cells[13].value === localStorage.getItem('user') && (props.row.cells[12].value === 'InsufficientFund' ||  props.row.cells[12].value === 'Pending')) ? 
          <Button color='primary' onClick={x=>renderModal(props.row.cells[0].value,props.row,theBanks)}>Edit</Button>  :
          
          
          (dd.indexOf(props.row.cells[13].value ) !== -1 && (props.row.cells[12].value === 'InsufficientFund' ||  props.row.cells[12].value === 'Pending'))
         ?  <Button color='primary' onClick={x=>retractOrder(props.row.cells[0].value)}>Retract</Button> 
         : null
      }

  }],[downlines,statusCallBack]
    );
    
    
  const submitEdit =(orderCode)=>{
    if(isNaN(parseInt(values.bank_name.toString()))){
      setResponseTitle('Edit order');
     setResponseMessage('Please select a bank');
     setOpen(true);
      return ;
    }
    console.log( )
    const url = domainUrl + 'Orders/EditOrder';
    var auth = localStorage.getItem('authorization');
    var query = {  
      "OrderCode": orderCode, 
      "SenderContactNo":values.sender_phone,  
      "BankId":values.bank_name,  
      "RecipientAccountName":values.recipient_contact,
      "RecipientAccountNo":values.bank_account,
      "Amount": values.my_amount,
      "Express": values.is_express,
      "Fee": values.my_fee_myr,
      "Rate":values.my_rate_idr
    }
    
    console.log(query)
    var query2 = JSON.stringify(query);
    console.log(query2)
    props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth}, renderResponseModal );  

  }
  
  
  const retractOrder = (code )=>{ 
     
    setEditCode(code);
    setResponseTitle('Retract Order');
    setResponseMessage('Do you want to retract order?');
    setDialogOpen(true);
  }

  const renderModal = (code, row,theBanks)=>{ 
    console.log(theBanks)
    var bankid = banks.indexOf(row.cells[5].value);
    console.log(bankid)
    setEditCode(code)
      setValues({
        ...values,
        recipient_contact: row.cells[3].value, 
        bank_name: row.cells[5].value ,
        bank_account: row.cells[4].value, 
        my_fee_myr:parseFloat(row.cells[10].value),
        my_amount:parseFloat(row.cells[6].value),
        my_rate_idr: parseFloat(row.cells[8].value),
        my_obtainedrate_idr: parseFloat(row.cells[9].value), 
        is_express: false, 
        sender_phone: row.cells[2].value, 

      });
      setSelectedOrder({
        recipient_contact: row.cells[3].value, 
        bank_name: row.cells[5].value,
        bank_account: row.cells[4].value, 
        my_fee_myr:parseFloat(row.cells[10].value),
        my_amount:parseFloat(row.cells[6].value),
        my_rate_idr: parseFloat(row.cells[8].value),
        my_obtainedrate_idr: parseFloat(row.cells[9].value), 
        is_express: false, 
        sender_phone: row.cells[2].value, 
          
      })
    setModalOpen(true);
  }
 
  const renderResponseModal = (data)=>{
    console.log(data)
    if(data){
     var title= 'Edit Order'
     var message = 'Order edited successfully'
     setResponseTitle(title);
     setResponseMessage(message);
    }

    setOpen(true);
  }

  const handleSelectAll = event => { 

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = orders.map(orders => orders.id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  
  const handleModalClose = () => {
    setModalOpen(false);
  };
    
  useEffect(() => {
     setBanks(banks);
     
    console.log(banks) 
  },[banks]);
 
  useEffect(() => { 
    
    if(selectedOrder !== null  && banks.length > 0){
      for(var b in banks){
        if(banks[b].bankName === selectedOrder.bank_name){
          var bankId = parseInt(banks[b].id);
          console.log(bankId) 
          setValues({...values, bank_name:bankId});
          handleGetRates(bankId)
          
           
        }
      }
    }
  },[selectedOrder]);

  useEffect(() => {
    if(obtainRate){
      setValues({...values, 
        my_obtainedexpress: obtainRate.obtainedExpressFee,
        my_obtainedrate_idr: obtainRate.obtainedRate,
        bank_fee_idr: obtainRate.obtainedBankFee*obtainRate.obtainedRate, 
        fee_earning:values.my_fee_myr -parseFloat(obtainRate.obtainedStandardFee) - parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0), 
        my_total:  values.my_amount+values.my_fee_myr+ parseFloat(obtainRate.obtainedStandardFee)+ 
        parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0)+parseFloat(obtainRate.obtainedExpressFee), 
        my_total_idr:  (values.my_amount+values.my_fee_myr+ parseFloat(obtainRate.obtainedStandardFee)+ 
        parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0)+parseFloat(obtainRate.obtainedExpressFee))*obtainRate.obtainedRate,
    });
    }
    
},[obtainRate]);

useEffect(() => {
  


  if(obtainRate){
    setValues({...values, 
      my_total:  values.my_amount+values.my_fee_myr+ parseFloat(obtainRate.obtainedStandardFee)+ 
parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0)+parseFloat(obtainRate.obtainedExpressFee) , 
my_total_idr:  (values.my_amount+values.my_fee_myr+ parseFloat(obtainRate.obtainedStandardFee)+ 
parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0)+parseFloat(obtainRate.obtainedExpressFee))*obtainRate.obtainedRate,
fee_earning:values.my_fee_myr -parseFloat(obtainRate.obtainedStandardFee) - parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0) ,  
    
  });
  }
  
},[values.my_fee_myr,values.my_rate_idr,values.my_amount]);

const handleChangeSwitch= event => {
  setValues({ 
    ...values,
    is_express: event.target.checked
  });
};
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    if(event.target.name == 'bank_name'){ 
      setValues({
        ...values,
        [event.target.name]: parseInt(event.target.value)
      });
      handleGetRates(parseInt(event.target.value))
    } 
    if(event.target.name == 'my_fee_myr' || event.target.name == 'my_amount' || event.target.name == 'my_rate_idr' ){
      setValues({
        ...values,
        [event.target.name]: parseFloat(event.target.value)
      });
    } 
    if(event.target.name == 'my_amount'){
      setValues({
        ...values,
        [event.target.name]: parseFloat(event.target.value)
      });
    } 
    console.log(event.target.name,event.target.value )
    
  };

  return (

     orders.length >0 ?
     <React.Fragment>
       <TTable classes={classes} columns={columns} data={orders} className={className} {...rest} />
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">{responseTitle}</h2>
        <p id="simple-modal-description">
          {responseMessage}
        </p> 
        </div>
      </Modal>
      <Modal
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <form
            autoComplete="off"
            noValidate
          >
            <CardHeader
              subheader=""
              title={responseTitle}
            />
            <Divider />
            <CardContent>
           
        <p id="simple-modal-description">  {responseMessage}</p> 
        </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                onClick={x=> handleToRetract(editCode)}
              >
                Retract Order {editCode}
              </Button>
            </CardActions>
          </form>
        </Card>
      </Modal>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        
      >
        <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title"> Edit Order <br/>Current Rate:   {obtainRate!== null ? obtainRate.obtainedRate: 0.0}</h2>
        <div id="simple-modal-description"> 
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <form
            autoComplete="off"
            noValidate
          >
            <CardHeader
              subheader={"Edit order -" +editCode}
              title="Edit Order" 
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    helperText="Please specify recipient contact"
                    label="Recipient Contact"
                    margin="dense"
                    name="recipient_contact"
                    onChange={handleChange}
                    required
                    value={values.recipient_contact}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Select Bank"
                    margin="dense"
                    name="bank_name"
                    onChange={handleChange}
                    required
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={values.bank_name}
                    variant="outlined"
                  >
                    <option
                        key={-1}
                        value={'-1'}
                      >
                        {'Please Select.. '}
                      </option>
                    {banks.map(option => (
                      <option
                        key={option.id}
                        value={option.id}
                      >
                        {option.bankName}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Sender Contact"
                    margin="dense"
                    name="sender_phone"
                    onChange={handleChange}
                    required
                    
                    value={values.sender_phone}
                    variant="outlined"
                  > 
                  </TextField>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Bank Account"
                    margin="dense"
                    name="bank_account"
                    onChange={handleChange}
                    required
                    
                    value={values.bank_account}
                    variant="outlined"
                  > 
                  </TextField>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="My Fee"
                    margin="dense"
                    name="my_fee_myr"
                    onChange={handleChange}
                    required
                    
                    value={values.my_fee_myr}
                    variant="outlined"
                  > 
                  </TextField>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Bank Fee (IDR)"
                    margin="dense"
                    name="bank_fee_idr" 
                    required
                    disabled
                    
                    value={values.bank_fee_idr}
                    variant="outlined"
                  > 
                  </TextField>
                </Grid>
                
              </Grid>  
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Amount"
                    margin="dense"
                    name="my_amount"
                    onChange={handleChange}
                    required
                    
                    value={values.my_amount}
                    variant="outlined"
                  > 
                  </TextField>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="My Rate"
                    margin="dense"
                    name="my_rate_idr"
                    onChange={handleChange}
                    required
                    
                    value={values.my_rate_idr}
                    variant="outlined"
                  > 
                  </TextField>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <FormGroup row>
                    <FormControlLabel
                      control={<Switch
                              checked={values.is_express}
                              onChange={handleChangeSwitch}
                              name="is_express"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                              />
                            }
                            label="Is Express"
                          /> 
                </FormGroup>
                  
                  
                </Grid>
                
              </Grid>

              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="My Rate Earning (MYR)"
                    margin="dense"
                    name="rate_earning"  
                    disabled
                    value={values.rate_earning}
                    variant="outlined"
                  > 
                  </TextField>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="My Fee Earning (MYR)"
                    margin="dense"
                    name="fee_earning"
                    disabled
                    value={values.fee_earning}
                    variant="outlined"
                  > 
                  </TextField>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Total (MYR)"
                    margin="dense"
                    name="my_total" 
                    required
                    disabled
                    
                    value={values.my_total}
                    variant="outlined"
                  > 
                  </TextField>
                </Grid>
                
              </Grid>

              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Total (IDR)"
                    margin="dense"
                    name="my_total_idr" 
                    required
                    disabled
                    
                    value={values.my_total_idr}
                    variant="outlined"
                  > 
                  </TextField>
                </Grid>
                
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                onClick={x=> submitEdit(editCode)}
              >
                Edit Order {editCode}
              </Button>
            </CardActions>
          </form>
        </Card>
        </div> 
        </div>
      </Modal>
    </React.Fragment>
   :null
    
  );
};

OrdersTable.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array.isRequired,
  posttoserverwithheader: PropTypes.func,
  handleSubmitOrder: PropTypes.func,
  banks:PropTypes.array,
  selectedBank: PropTypes.string,
  obtainRate:PropTypes.object,  
 handleGetRates:  PropTypes.func,
 handleRetractOrder:PropTypes.func,
 downlines: PropTypes.array.isRequired,
 statusCallBack:PropTypes.string,
};

export default OrdersTable;
 

/*
<Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="My Express Fee"
                    margin="dense"
                    name="my_expressfee_myr"
                    onChange={handleChange}
                    required
                    disabled={!values.is_express}
                    value={values.my_expressfee_myr}
                    variant="outlined"
                  > 
                  </TextField>
                </Grid>
                */