import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  LinearProgress,
  CardHeader,
  Grid,
  TextField
} from '@material-ui/core';
import { SessionContext } from '../../../contexts/SessionContext';
import ReactPasswordStrength from 'react-password-strength';

const useStyles = makeStyles((theme:any) => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));
const purl = 'http://localhost:5000/Users/CreateUser';

const NewAccountForm = props => {
  const { className,postToServer, ...rest } = props;

  const [state1, setContextState] = useContext(SessionContext); 
  const classes:any = useStyles();

  const [values, setValues] = useState({
    Nickname: '', 
    Username:'',
    Email: 'shen.zhi@devias.io',
    ContactNo: '',
    Password: '',
    //parent: '',
    Language:'English',
  });

  const Language = [{
    label: 'English',
    value: 'English',

  },
  {
    label: '中文',
    value: 'Chinese',

  }];

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  
  const handleCreateUser =()=>{
    var query = JSON.stringify(values);
    props.postToServer(purl,query, {'Content-Type':'application/json', 'Authorization': state1.authorizationToken},handleResponse );    
  }

  const handleResponse =(data) =>{
    if(data.statusText === "OK"){
      console.log("User Registered")
      
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="New user account information"
          title="New user"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the user name"
                label="Username"
                margin="dense"
                name="Username"
                onChange={handleChange}
                required
                value={values.Username}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the password"
                type="password"
                label="Password"
                margin="dense"
                name="Password"
                onChange={handleChange}
                required
                value={values.Password}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the nick name"
                label="Nick Name"
                margin="dense"
                name="Nickname"
                onChange={handleChange} 
                value={values.Nickname}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Contact"
                label="Contact"
                margin="dense"
                name="ContactNo"
                onChange={handleChange} 
                value={values.ContactNo}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Select language"
                margin="dense"
                name="Language"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.Language}
                variant="outlined"
              >
                {Language.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="Email"
                onChange={handleChange} 
                value={values.Email}
                variant="outlined"
              />
            </Grid>
           
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={ handleCreateUser}
          >
            Create new user account
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

NewAccountForm.propTypes = {
  className: PropTypes.string,
  postToServer: PropTypes.func,
  sethttppostcontext:  PropTypes.func,
  httppostcontext:  PropTypes.any,
};

export default NewAccountForm;

/*
        <div className={classes.progress}>
          <Typography variant="body1">Profile Completeness: 70%</Typography>
          <LinearProgress
            value={70}
            variant="determinate"
          />
        </div>

        <Button
          className={classes.uploadButton}
          color="primary"
          variant="text"
        >
          Upload picture
        </Button>
        <Button variant="text">Remove picture</Button>
        */
