import React, { Component } from 'react'
import styles from './Invoice.module.scss'

import LineItems from './LineItems'
import { HttpPostService } from '../../../services/HttpPostService';
import { HttpPostServiceContextConsumer } from '../../../contexts/HttpServiceContext';

import uuidv4 from 'uuid/v4'
import {receiptUrl, payDomain} from '../../../constants';


function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  
  const regex = new RegExp(`[\\?&]${name}"=([^&#]*)`);  
  // const s = new  URLSearchParams(window.location.hash)
// const s = `http://${str[1]}`;
// const url = new URL(s);
if(window.location.search !== null || window.location.search !== undefined){
  const s1 = new  URLSearchParams(window.location.search)  
   const results = s1.get(name);
   return results === null ? '' : decodeURIComponent(results);
}
return '';
};

const getBody = (thisquery, variables) => {
  return {
    OperationName: "",
    query: thisquery,
    Variables: variables
  };
};

//let token = decodeURIComponent(getUrlParameter('t'));
let tid = decodeURIComponent(getUrlParameter('tid'));
let sellerOrderID = decodeURIComponent(getUrlParameter('soid'));
let checksum =decodeURIComponent(getUrlParameter('cs')); 
let total =decodeURIComponent(getUrlParameter('total')); 
let type =decodeURIComponent(getUrlParameter('type')); 
var today = new Date();
var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
class Invoice extends Component  {

  locale = 'en-US'
  currency = 'USD'

  state = {
    taxRate: 0.00,
    lineItems: [],
    transId: '',
    receiptDate: '',
    sellerOrderID:'',
    bankBranch:'',
    status:'',

      /*{
        id: 'initial',      // react-beautiful-dnd unique key
        name: '',
        description: '',
        quantity: 0,
        price: 0.00,
      },*/ 
  }
 
  componentDidMount( )   {
    console.log(this.props.posttoserverwithheader)
    const url =  `${payDomain}omniql`; 
    const query = `query{ getreceipt(type:"${type}", soid:"${sellerOrderID}",tid:"${tid}",checksum:"${checksum}"  ,total:${total}) { items{item,quantity,unitprice,description}, receiptDate, receiptId, sellerOrderID, taxamount, taxrate, totalprice,bankBranch, status,fpxId}}`;
   

   var query2 = JSON.stringify(getBody(query,{})); 
   let that = this;
   async function fd(e){ e(url,query2, {'Content-Type':'application/json' },that.handleReceiptResponse)};
   
   fd(this.props.posttoserverwithheader);
   

} ;


  handleReceiptResponse =(data)=>{
  console.log(data)
  if(data.data){
    if(data.data.data.getreceipt){
      var ret =  data.data.data.getreceipt ;
      var li = this.state.lineItems;

      var count = 1;
      for(var i in ret.items){
        console.log(i)
       var it = {
          id: count + "",      
          name: ret.items[i].item,
          description: decodeURIComponent( ret.items[i].description),
          quantity: ret.items[i].quantity,
          price: ret.items[i].unitprice,
        }
        console.log(it)
        li.push(it);
        count++;
      }

      this.setState({
        lineItems:li,
        transId: ret.fpxId,
        receiptDate: new Date(ret.receiptDate),
        sellerOrderID: ret.sellerOrderID,
        bankBranch: ret.bankBranch,
        status: ret.status
      })
    console.log(ret);
    }
  }
}

  handleInvoiceChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }

  handleLineItemChange = (elementIndex) => (event) => {
    let lineItems = this.state.lineItems.map((item, i) => {
      if (elementIndex !== i) return item
      return {...item, [event.target.name]: event.target.value}
    })
    this.setState({lineItems})
  }

  handleAddLineItem = (event) => {
    this.setState({
      // use optimistic uuid for drag drop; in a production app this could be a database id
      lineItems: this.state.lineItems.concat(
        [{ id: uuidv4(), name: '', description: '', quantity: 0, price: 0.00 }]
      )
    })
  }

  handleRemoveLineItem = (elementIndex) => (event) => {
    this.setState({
      lineItems: this.state.lineItems.filter((item, i) => {
        return elementIndex !== i
      })
    })
  }

  handleReorderLineItems = (newLineItems) => {
    this.setState({
      lineItems: newLineItems,
    })
  }

  handleFocusSelect = (event) => {
    event.target.select()
  }

  handlePayButtonClick = () => {
    alert('Not implemented')
  }

  formatCurrency = (amount) => {
    return (new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount))
  }

  calcTaxAmount = (c) => {
    return c * (this.state.taxRate / 100)
  }

  calcLineItemsTotal = () => {
    return this.state.lineItems.reduce((prev, cur) => (prev + (cur.quantity * cur.price)), 0)
  }

  calcTaxTotal = () => {
    return this.calcLineItemsTotal() * (this.state.taxRate / 100)
  }

  calcGrandTotal = () => {
    return this.calcLineItemsTotal() + this.calcTaxTotal()
  }

  render = () => {
    return (

      <div className={styles.invoice}>
        <div className={styles.brand}>
          <img src="http://infinityss.com.my/wp-content/uploads/2021/02/ISS.jpg" alt="Logo" className={styles.logo} />
        </div>
        <div className={styles.addresses}>
          <div className={styles.from}>
            <strong>Infinity Success Solutions Sdn Bhd</strong><br />
              No 27, Jalan 7/40,<br />
              Taman Pusat Kepong, 53300, Kuala Lumpur <br />
              +603-6252 5188
          </div>
          <div>
            <div className={`${styles.valueTable} ${styles.to}`}>
              <div className={styles.row}>
                <div className={styles.label}>FPX Transaction ID #</div>
                <div className={styles.value}>{this.state.transId}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Receipt #</div>
                <div className={styles.value}>{this.state.sellerOrderID}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Date</div>
                <div className={`${styles.value} ${styles.date}`}>{`${this.state.receiptDate}`}</div>
              </div>
            </div>
          </div>
        </div>
        <h2>Receipt</h2>

          <LineItems
            items={this.state.lineItems}
            currencyFormatter={this.formatCurrency}
            reorderHandler={this.handleReorderLineItems}
            /*addHandler={this.handleAddLineItem}
            changeHandler={this.handleLineItemChange}
            focusHandler={this.handleFocusSelect}
            deleteHandler={this.handleRemoveLineItem}
            */
          />

        <div className={styles.totalContainer}>
          <form>
            <div className={styles.valueTable}>
              <div className={styles.row}>
                <div className={styles.label}>Bank</div>
                <div className={styles.value}>{this.state.bankBranch}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Status</div>
                <div className={styles.value}>{this.state.status}</div>
              </div>
            </div>
          </form>
          <form>
            <div className={styles.valueTable}>
              <div className={styles.row}>
                <div className={styles.label}>Subtotal</div>
                <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcLineItemsTotal())}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Tax ({this.state.taxRate}%)</div>
                <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcTaxTotal())}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Total Payment</div>
                <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcGrandTotal())}</div>
              </div>
            </div>
          </form>
        </div>

        <div className={styles.pay}>
          Payment Status: <span className={styles.payNow}></span>
        </div>

        <div className={styles.footer}>
          <div className={styles.comments}>
            <h4>Notes</h4>
            <p>This receipt is computer generated <b>No Signature Required</b>.</p>
            <p></p>
          </div>
          <div className={styles.closing}>
            <div>Thank-you for your business</div>
          </div>
        </div>

      </div>

    )
  }

}


const HOCInvoice =() =>{
  return ( 
          <HttpPostServiceContextConsumer> 
              {({ 
                  postToServerWithHeader  })=>
              (
                  
                      <Invoice posttoserverwithheader ={postToServerWithHeader}/>
                  
          
          )}
      </HttpPostServiceContextConsumer> 
  );

  }
 
const HOCReceiptMain  = () => {
  return (
      
  <HttpPostService    > 
      <HOCInvoice /> 
  </HttpPostService>
  );
} 


export default HOCReceiptMain


//<input name="taxRate" type="number" step="0.01" value={this.state.taxRate} onChange={this.handleInvoiceChange} onFocus={this.handleFocusSelect} />