import React,{  useState } from 'react';

 const SessionContext =React.createContext([]);


const SessionContextProvider = (props) => {
    const [state1, setContextState] = useState({
        isLoggedIn:false,
        authorizationToken:'',
    });
    return (
      <SessionContext.Provider value={[state1, setContextState]}>
        {props.children}
      </SessionContext.Provider>
    );
  }
  
  export { SessionContext, SessionContextProvider };