import React, { useState, useEffect, useContext, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import { HttpPostServiceContextConsumer, HttpGetServiceContextConsumer, httpGetVar } from '../contexts/HttpServiceContext';
import { CardMedia, Typography } from '@material-ui/core';
import {  Redirect   } from 'react-router-dom';
import customHistory from "../history.js";
import { SessionContext } from '../contexts/SessionContext';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: 400,
      margin: `${theme.spacing(0)} auto`
    },
    loginBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1
    },
    header: {
      textAlign: 'center',
      background: '#212121',
      color: '#fff',
      fontSize:22,
      fontWeight:'bold'
    },
    card: {
      marginTop: theme.spacing(10)
    }

  }),
);

const purl =  'http://localhost:5000/auth/Login';//'http://sys.geotech.solutions:8080/auth/Login';
const gurl = 'http://localhost:5000/auth/GetCaptcha'; // 'http://sys.geotech.solutions:8080/auth/GetCaptcha';


const Login = (props) => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCap] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [helperText, setHelperText] = useState('');
  const [error, setError] = useState(false);
  const [showOnce, setShowOnce] = useState(false);
  const getServ = useContext(httpGetVar); 
  const [state1, setContextState] = useContext(SessionContext); 
  //const [b64Captcha, setCaptcha] = useState('');
  //const [captchaData, setCaptchaData] = useState('');
  useEffect(() => {
    if (username.trim() && password.trim()&& captcha.trim()) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [username, password,captcha]);

  useEffect(() => {
   // console.log(localStorage.getItem('isLoggedIn'))
   // console.log(state1)
    if (state1.isLoggedIn) {
      customHistory.push('/dashboard')
    }  

  });

  const myFunction = () => {
    return getServ.genericGetHttp(gurl,'', {}, {});
  }
  
  const useAsync = (asyncFunction, immediate = true) => {
    const [pending, setPending] = useState(false);
    const [b64Captcha, setCaptcha] = useState(null);
    const [captchaData, setCaptchaData] = useState(null);
    const [error2, setError] = useState(null);
  
    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
 
      const execute = useCallback(() => {
        setPending(true);
        setCaptcha(null);
        setError(null);
        return asyncFunction()
          .then(response => { 
            setCaptcha('data:image/png;base64,'+ response.data.captchaImage);
            setCaptchaData(response.data.captchaData);
            setPending(false)
        })
          .catch(error => setError(error))
          .finally(() => setPending(false));
      }, [asyncFunction]);
      
      useEffect(() => {
        if (immediate) {
          execute();
        }
      }, [execute, immediate]);
     
    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
      return { execute, pending, b64Captcha, captchaData, error2 };
   
  
  };

  const { execute, pending, b64Captcha, captchaData, error2 } = useAsync(myFunction, false); 
  
  useEffect(() => {
    if( !state1.isLoggedIn){
      if(!showOnce ){
        execute();
        setShowOnce(true);
      }
    }
  });
  const handleLogin = () => {
    //var q = `query{login( username:"${username}", password:"${password}") { message, success,sessionKey,apiKey }}`;
    var query = {
      "AuthenticateModel": {
        "Username": username,
        "Password": password,
      },
      "CaptchaCode": captcha,
      "CaptchaData": captchaData, 
    }
    props.postToServer(purl,query, {'Content-Type':'application/json'},handleResponse );
    /*if (username === 'abc@email.com' && password === 'password') {
      setError(false);
      setHelperText('Login Successfully');
    } else {
      setError(true);
      setHelperText('Incorrect username or password')
    }*/
  };

  const handleResponse =(data)=>{
    console.log(data);
    if(data.authorization !== undefined && data.authorization !== ''){
      localStorage.setItem('authorization', data.authorization);
      localStorage.setItem('isLoggedIn', 'true'); 
      localStorage.setItem('type', data.type); 
      localStorage.setItem('user', data.username); 
      //setContextState(state => ({ ...state, isLoggedIn: true,authorizationToken: data.authorization}))
      customHistory.push('/dashboard');
    }
    else{
      alert ('Invalid Captcha or login information')
    }
  }

  const handleKeyPress = (e:any) => {
    if (e.keyCode === 13 || e.which === 13) {
      isButtonDisabled || handleLogin();
    }
  };

  return ( 
          <React.Fragment > 
            <form className={classes.container} noValidate autoComplete="off" >
              <Card className={classes.card} onLoadStart={e=> execute}>
                <CardHeader className={classes.header} disableTypography={true} title='OMNIPay Login'/> 
                <CardContent>
                  <div  >
                    <TextField
                      error={error}
                      fullWidth
                      id="username"
                      type="email"
                      label="Username"
                      placeholder="Username"
                      margin="normal"
                      onChange={(e)=>setUsername(e.target.value)}
                      onKeyPress={(e)=>handleKeyPress(e)}
                    />
                    <TextField
                      error={error}
                      fullWidth
                      id="password"
                      type="password"
                      label="Password"
                      placeholder="Password"
                      margin="normal"
                      helperText={helperText}
                      onChange={(e)=>setPassword(e.target.value)}
                      onKeyPress={(e)=>handleKeyPress(e)}
                    /> 
                    <CardMedia
                    component="img"
                    alt="Try Captcha"
                    height="80"
                    src={ b64Captcha}
                    title="Try Captcha" 
                    onClick={execute}
                    style={{cursor:'pointer'}}
                  />
                  <TextField
                      error={error}
                      fullWidth
                      id="captcha"
                      type="text"
                      label="Captcha"
                      placeholder="Captcha"
                      margin="normal" 
                      onChange={(e)=>setCap(e.target.value)}
                      onKeyPress={(e)=>handleKeyPress(e)}
                    /> 
                  </div>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    className={classes.loginBtn}
                    onClick={()=>handleLogin()}
                    disabled={isButtonDisabled}>
                    Login
                  </Button>
                </CardActions>
              </Card>
            </form>
          </React.Fragment> 
  );
}

export default Login;