import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { IconButton, Grid, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { HttpPostServiceContextConsumer, HttpGetServiceContextConsumer } from '../../contexts/HttpServiceContext';
import {domainUrl} from '../../constants';

import     {ProductsToolbar}    from './components';
import   {ProductCard}   from './components';
// import mockData from './data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const ProductList =props => {
  const classes = useStyles();

  const [products] = useState([]);

  const handleGetRate = event => {
    const url = domainUrl + 'Products/GetProductRate';
    var auth = localStorage.getItem('authorization');
    var query = {  
     "ProductName": "MYR/IDR", 
   }

   var query2 = JSON.stringify(query);
   console.log(auth)
   props.postToServerWithHeader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleResponse );  
 };
 
 const handleResponse =(data)=>{
  console.log(data);
}

  return (
    <div className={classes.root}> 
      <div className={classes.content}>
        <Grid
          container
          spacing={3}
        >
          {products.map(product => (
            <Grid
              item
              key={product.id}
              lg={4}
              md={6}
              xs={12}
            >
              <ProductCard product={product} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};
 


const HocProduct = (props) => {  
  return (
    
<HttpPostServiceContextConsumer> 
    {({ httpPostContext,
        setHttpPostContext,
        postToServerWithHeader  })=>
    (
      <React.Fragment> 
        <ProductList {...props} postToServerWithHeader={postToServerWithHeader}  sethttppostcontext={setHttpPostContext} httppostcontext={httpPostContext}></ProductList>
       </React.Fragment>
    )}
</HttpPostServiceContextConsumer>
  );
}


HocProduct.propTypes = {
 className: PropTypes.string,
 postToServer: PropTypes.func,
 sethttppostcontext:  PropTypes.func,
 httppostcontext:  PropTypes.any,
};
export default HocProduct;

/*

      <div className={classes.pagination}>
        <Typography variant="caption">1-6 of 20</Typography>
        <IconButton>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton>
          <ChevronRightIcon />
        </IconButton>
      </div>
*/