import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import {domainUrl} from '../../../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    display:'none'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const NetworthBalance = props => {
  const { className,posttoserverwithheader, ...rest } = props;
  const [mybalance, setMyBalance] = useState(0.0);
  const classes = useStyles();
  const [responseMessage,setResponseMessage]= useState('');
  const [responseTitle,setResponseTitle]= useState('');
  const [open, setOpen] = React.useState(false);

  const handleSubmitGetOwnNetworth = ()=>{
    const url = domainUrl + 'UserNetworths/GetNetworth';
    var auth = localStorage.getItem('authorization');
    var query = {  
    //  "Username": values.username, 
    }
     
    var query2 = JSON.stringify(query);
    //console.log(query2)
    posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},  x=> renderResponseModal(x, 'User Networth',x.data !== undefined && x.data !== ""? 'Search user '+x.data+' successfully' : 'Search Failed', false)  );  
    }

    useEffect(() => { 
      handleSubmitGetOwnNetworth();
  },[]);

    
    const renderResponseModal = (data, title, message, isOpen)=>{ 
       setResponseTitle(title);
       setResponseMessage(message); 
       setOpen(isOpen); 
       setMyBalance(data.data.networth);
    }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
               Balance
            </Typography>
            <Typography variant="h3">MYR {mybalance.toFixed(2)}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <MoneyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          <ArrowDownwardIcon className={classes.differenceIcon} />
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            12%
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            Since last month
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

NetworthBalance.propTypes = {
  className: PropTypes.string
};

export default NetworthBalance;
