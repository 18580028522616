import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid,
  Card,
  CardActions,
  CardContent,
  Modal ,
  Button,
  CardHeader,
  Divider, } from '@material-ui/core';
  import clsx from 'clsx';
import PropTypes from 'prop-types';

import  { OrderEx,ProductInfo}   from './components';
import { HttpPostServiceContextConsumer } from '../../contexts/HttpServiceContext';
import {domainUrl} from '../../constants';

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Order = props => {
  const classes = useStyles();
  const [banks, setBanks]=useState([]);
  const [rates, setRate]=useState(null);
  const [responseMessage,setResponseMessage]= useState('');
  const [responseTitle,setResponseTitle]= useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  
  const handleResponse =(data)=>{
    if(data.data){
      
    setResponseTitle('Place Order Successful');
    setResponseMessage('New order placed successfully');
    setDialogOpen(true);
    }
    else
    {
      setResponseTitle('Place Order Failed');
      setResponseMessage('Failed to place new order');
      setDialogOpen(true);
    }
  }

  const handleBankResponse =(data)=>{ 
    setBanks(data.data);
  }

  const handleRate =(data)=>{
    setRate(data.data);
  }

  
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
 
  useEffect(() => {
    handleGetBanks();
    handleGetRates();
},[]);

  const _handleSubmitOrder = (order) => {
    
     const url = domainUrl + 'Orders/CreateOrder';
     var auth = localStorage.getItem('authorization');
     var query = {  
      "SenderContactNo": order.sender_phone,
      "ProductId": 1 , 
      "BankId":order.bank_name,
      "RecipientAccountName":order.recipient_contact,
      "RecipientAccountNo":order.bank_account,
      "Amount":order.my_amount,
      "Express":order.is_express,
      "Rate":order.my_rate_idr,
      "Fee":order.my_fee_myr
    }

    var query2 = JSON.stringify(query);
    console.log(auth)
    props.postToServerWithHeader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleResponse );  
  };

  
  const handleGetBanks = () => {
    const url = domainUrl + 'Banks/GetActiveBanks';
    var auth = localStorage.getItem('authorization');
    var query = {  
      "Pagination":{
        "OrderByKeys":null,
        "PageSize":50,
        "PageNum":0
      }
   }

   var query2 = JSON.stringify(query);
   console.log(auth)
   return props.postToServerWithHeader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleBankResponse
  );  
 };

 
  
 const handleGetRates  = (bankid = 0) => {
  const url = domainUrl + 'Spreads/GetObtainedSpread';
  var auth = localStorage.getItem('authorization');
  var query = {   
    "ProductId" : 1,
    "BankId":bankid 
 }

 var query2 = JSON.stringify(query);
 console.log(auth)
 return props.postToServerWithHeader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleRate
);  
};

  return (
    <div className={classes.root}>
      <Modal
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Card 
          className={clsx(classes.root)}
        >
          <form
            autoComplete="off"
            noValidate
          >
            <CardHeader
              subheader=""
              title={responseTitle}
            />
            <Divider />
            <CardContent>
          
        <p id="simple-modal-description">  {responseMessage}</p> 
        </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                onClick={x=> handleDialogClose()}
              >
                Close
              </Button>
            </CardActions>
          </form>
        </Card>
      </Modal>

        <Grid
            container
            spacing={4}
        >
          
            <Grid
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
            >
                <OrderEx handleSubmitOrder={_handleSubmitOrder} banks={banks} obtainRate={rates} handleGetRates={handleGetRates}  />
            </Grid>
            <Grid
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
            >
                <Grid
                container
                spacing={4}
                >  
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xl={12}
                        xs={12}
                        >
                            <ProductInfo obtainRate={rates} />
                        </Grid>
                        
                </Grid>
            </Grid>
        </Grid>

    </div>
  );
};

const HocOrder = (props) => {  
  return (
    
<HttpPostServiceContextConsumer> 
    {({ httpPostContext,
        setHttpPostContext,
        postToServerWithHeader  })=>
    (
      <React.Fragment> 
        <Order {...props} postToServerWithHeader={postToServerWithHeader}  sethttppostcontext={setHttpPostContext} httppostcontext={httpPostContext}></Order>
       </React.Fragment>
    )}
</HttpPostServiceContextConsumer>
  );
}


HocOrder.propTypes = {
 className: PropTypes.string,
 postToServer: PropTypes.func,
 sethttppostcontext:  PropTypes.func,
 httppostcontext:  PropTypes.any,
};
export default HocOrder;
