import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {domainUrl} from '../../../constants';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { HttpPostServiceContextConsumer } from '../../../contexts/HttpServiceContext';

const useStyles = makeStyles((theme )=> ({
  root: {},
  
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  } 
}));

function rand() {
    return Math.round(Math.random() * 20) - 10;
  }
  
  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      overflow:'auto'
    };
  }

const SearchUserforNetworth = props => {
    const { className,posttoserverwithheader,   ...rest } = props;
    
  const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();  
    const [values,setValues]=useState({
        username: '',
        networth: 0,
        networthmode:'',
    })
    const [foundUser, setFoundUser] = useState(''); 
    
  const [responseMessage,setResponseMessage]= useState('');
  const [responseTitle,setResponseTitle]= useState('');
  const [open, setOpen] = React.useState(false);

    const modes = [{
        id:'TOPUP',
        modename:'Top Up'
    },{
        id:'DEDUCT',
        modename:'Deduct'
    }];

    
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    }); 
    
  };

  const handleSubmitTopUp = ()=>{
      if(values.networthmode === 'TOPUP'){

        const url = domainUrl + 'UserNetworths/TransferNetworth';
        var auth = localStorage.getItem('authorization');
        var query = {  
          "RecipientUsername": foundUser, 
          "Amount": parseFloat(values.networth)
         
        }
         
        var query2 = JSON.stringify(query);
        //console.log(query2)
        posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth}, x=> renderResponseModal(x, 'Top up User Networth',x.data !== undefined && x.data !== "" ? 'Top Up user '+x.data+' successfully' : 'Top Up Failed')   );  
      }
      else if (values.networthmode === 'DEDUCT'){

        const url = domainUrl + 'UserNetworths/DeductDownlineNetworth';
        var auth = localStorage.getItem('authorization');
        var query = {  
          "DownlineUsername": foundUser, 
          "Amount": parseFloat(values.networth)
         
        }
         
        var query2 = JSON.stringify(query);
        //console.log(query2)
        posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth}, x=> renderResponseModal(x, 'Deduct User Networth',x.data === ''? 'Deduct Networth Fail' : 'Deduct Networth Successfully') );  
      }
  }

  const handleSubmitSearch = ()=>{
    const url = domainUrl + 'UserNetworths/GetUserWithNetworth';
    var auth = localStorage.getItem('authorization');
    var query = {  
      "Username": values.username, 
    }
     
    var query2 = JSON.stringify(query);
    //console.log(query2)
    posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},  x=> renderResponseModal(x, 'User Networth',x.data !== undefined && x.data !== ""? 'Search user '+x.data+' successfully' : 'Search Failed')  );  
    }

  
    const handleClose = () => {
        setOpen(false);
      };
    const renderResponseModal = (data, title, message)=>{
        console.log(data) 
            if(message === '')
           message = 'Search user '+data.data+' successfully'
         setResponseTitle(title);
         setResponseMessage(message);
         setFoundUser(  data.data )
         setOpen(true); 
    
      }
return (
    <div>
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="Topup / Deduct Networth"
          title="Topup or Deduct Networth "
        />
        <Divider />
        <CardContent>
        <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify username"
                label="Username"
                margin="dense"
                name="username"
                onChange={handleChange}
                
                value={values.username}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            > <Button
            color="primary"
            variant="contained"
            onClick={handleSubmitSearch}
          >
            Search
          </Button> 
            </Grid>
          </Grid>
          
        <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth 
                label="User"
                margin="dense"
                name="foundUser"
                onChange={handleChange}
                required
                disabled
                value={foundUser}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                helperText="Please specify top up vaalue"
                label="Top Up Value"
                margin="dense"
                name="networth"
                onChange={handleChange}
                required
                value={values.networth}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={3}
              xs={6}
            >
               <TextField
                fullWidth
                label="Select Mode"
                margin="dense"
                name="networthmode"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.networthmode}
                variant="outlined"
              >
                <option
                    key={-1}
                    value={'-1'}
                  >
                    {'Please Select.. '}
                  </option>
                {modes.map(option => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.modename}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmitTopUp}
          >
             Submit
          </Button>
        </CardActions>
      </form>
    </Card>
     <Modal
     open={open}
     onClose={handleClose}
     aria-labelledby="simple-modal-title"
     aria-describedby="simple-modal-description"
   >
     <div style={modalStyle} className={classes.paper}>
     <h2 id="simple-modal-title">{responseTitle}</h2>
     <p id="simple-modal-description">
       {responseMessage}
     </p> 
     </div>
   </Modal>
   </div>
);
};




SearchUserforNetworth.propTypes = {
  className: PropTypes.string,
  handleSubmitTopUp: PropTypes.func,  
};


const HocNetworth= (props) => {  
    return (
      
  <HttpPostServiceContextConsumer> 
      {({ httpPostContext,
          setHttpPostContext,
          postToServerWithHeader  })=>
      (
        <React.Fragment> 
          <SearchUserforNetworth {...props} posttoserverwithheader={postToServerWithHeader}  sethttppostcontext={setHttpPostContext} httppostcontext={httpPostContext}></SearchUserforNetworth>
         </React.Fragment>
      )}
  </HttpPostServiceContextConsumer>
    );
  }
  
  
  HocNetworth.propTypes = {
   className: PropTypes.string,
   postToServer: PropTypes.func,
   sethttppostcontext:  PropTypes.func,
   httppostcontext:  PropTypes.any,
  };
  export default HocNetworth;
 