import React from 'react'; 
import { HttpPostService } from '../../services/HttpPostService';
import { HttpGetService } from '../../services/GetService';
import HocLogin from '../../HOC/HocLogin';
import {  withRouter } from 'react-router-dom';

const LoginMain: React.FC = () => {
    return (
        
    <HttpPostService    >
      <HttpGetService>
        <HocLogin />
    </HttpGetService>
  </HttpPostService>
    );
  } 

  
export default LoginMain;
