import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { HttpPostServiceContextConsumer, HttpGetServiceContextConsumer } from '../../contexts/HttpServiceContext';
import {domainUrl} from '../../constants';


import  { OrderEx,ProductInfo}   from './components';
//import mockData from './data'; 
import TellerOrdersTable from './components/OrderListTable/TellerOrdersTable.js';

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const TellerOrderInProcessList = (props) => {
  const classes:any = useStyles();
  const [orders,setOrders] = useState([]);
  const [banks, setBanks]=useState([]);
  const [rates, setRate]=useState(null);

  const handleCheckPendingOrder =(status)=>{
      
    const url = domainUrl + 'Orders/ListOrderTable';
    var auth = localStorage.getItem('authorization');
    var query = {  
      "ProductId": 1, 
      "OrderStatus":status, 
      'Authorization': auth ,
      "Pagination":{
        "OrderByKeys":null,
        "PageSize":50,
        "PageNum":1
      }
    }
    var query2 = JSON.stringify(query);
    props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleResponsePendingOrder );  

  }

  const handleResponsePendingOrder =(data)=>{
    if(data.data){
      setOrders(data.data); 
    }
  }

  const handleSelect = (event, nodeIds) => {
    setOrders(nodeIds);
  };
 
useEffect(() => {
  handleCheckPendingOrder('InProcess');
  
},[]);

const handleGetBanks = () => {
  const url = domainUrl + 'Banks/GetActiveBanks';
  var auth = localStorage.getItem('authorization');
  var query = {  
    "Pagination":{
      "OrderByKeys":null,
      "PageSize":50,
      "PageNum":0
    }
 }

 var query2 = JSON.stringify(query);
 console.log(auth)
 return props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleBankResponse
);  
};

const handleBankResponse =(data)=>{ 
  setBanks(data.data);
}

const handleRate =(data)=>{
  setRate(data.data);
}


useEffect(() => {
 // handleGetBanks();
 // handleGetRates();
},[]);



const handleGetRates  = (bankid = 0) => {
const url = domainUrl + 'Spreads/GetObtainedSpread';
var auth = localStorage.getItem('authorization');
var query = {   
  "ProductId" : 1,
  "BankId":bankid 
}

var query2 = JSON.stringify(query);
console.log(auth)
return props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleRate
);  
};

  return (
    <HttpPostServiceContextConsumer> 
      {({ httpPostContext,
          setHttpPostContext,
          postToServerWithHeader  })=>
      (
    <div className={classes.root}> 
      <div className={classes.content}>
        <TellerOrdersTable orders={orders} posttoserverwithheader={postToServerWithHeader}   banks={banks} obtainRate={rates}   handleGetRates={handleGetRates}  />
      </div>
    </div>
    )}
    </HttpPostServiceContextConsumer>
  );
};

const HOCTellerOrderInProcessList =()=>{
  
  return (<HttpPostServiceContextConsumer> 
        {({ httpPostContext,
            setHttpPostContext,
            postToServerWithHeader  })=>
        (
          
         
       <HttpGetServiceContextConsumer>
       {({ 
               genericGetHttp  })=> 
           (
             <TellerOrderInProcessList  posttoserverwithheader={postToServerWithHeader} genericgethttp={genericGetHttp}/>
            )
        }
        </HttpGetServiceContextConsumer>
        )}
    </HttpPostServiceContextConsumer>
  );
}

export default HOCTellerOrderInProcessList;
