import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Cookies } from 'react-cookie';
import { HttpPostServiceContextConsumer } from '../../contexts/HttpServiceContext';
import {
  NetworthBalance,
  TotalDownlines, 
  LatestOrders,
  LatestProducts
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = props => {
  const {posttoserverwithheader, ...rest}  = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <NetworthBalance posttoserverwithheader={props.posttoserverwithheader}/>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalDownlines posttoserverwithheader={props.posttoserverwithheader}/>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <LatestProducts posttoserverwithheader={props.posttoserverwithheader}/>
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestOrders />
        </Grid>
      </Grid>
    </div>
  );
};

//export default Dashboard;


const HocDashboard= (props) => {  
  return (
    
<HttpPostServiceContextConsumer> 
    {({ httpPostContext,
        setHttpPostContext,
        postToServerWithHeader  })=>
    (
      <React.Fragment> 
        <Dashboard {...props} posttoserverwithheader={postToServerWithHeader}  sethttppostcontext={setHttpPostContext} httppostcontext={httpPostContext}></Dashboard>
       </React.Fragment>
    )}
</HttpPostServiceContextConsumer>
  );
}


HocDashboard.propTypes = {
 className: PropTypes.string,
 postToServer: PropTypes.func,
 sethttppostcontext:  PropTypes.func,
 httppostcontext:  PropTypes.any,
};
export default HocDashboard;
/*
<Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TasksProgress />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalProfit />
        </Grid> 
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <UsersByDevice />
        </Grid>
        
        */