import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'; 
import { HttpPostServiceContextConsumer, HttpGetServiceContextConsumer } from '../contexts/HttpServiceContext';
import Login from '../components/Login';

 
const HocLogin = () => { 
 const con = (input) =>{
  return true;
 }
  return (
    
<HttpPostServiceContextConsumer> 
    {({ httpPostContext,
        setHttpPostContext,
        postToServer  })=>
    (
      <React.Fragment>
        <HttpGetServiceContextConsumer>
        {({ 
                genericGetHttp  })=>
                con(genericGetHttp) && 
            (
              <Login  genericGetHttp={genericGetHttp} postToServer={postToServer} setHttpPostContext={setHttpPostContext} httpPostContext={httpPostContext}
             
                
              />
            )}
        </HttpGetServiceContextConsumer>
            </React.Fragment>
    )}
</HttpPostServiceContextConsumer>
  );
}

export default HocLogin;