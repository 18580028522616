import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles'; 
import 'react-perfect-scrollbar/dist/css/styles.css';
import {domainUrl} from '../../../../constants';
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
  useRowSelect
} from 'react-table';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Modal ,
  Button,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  FormLabel,
  FormHelperText,
  CheckboxProps,
} from '@material-ui/core';

import { getInitials } from '../../../../helpers';
import { Label } from '@material-ui/icons';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow:'auto'
  };
}
const useStyles = makeStyles((theme )=> ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));


const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)


const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
 
  if(   id === 'remarks')
  return <input value={value === null ? "" : value} onChange={onChange} onBlur={onBlur} style={{paddingTop:'10.5px', paddingBottom:'10.5px'}} />
  else
  return  <label   style={{paddingTop:'10.5px', paddingBottom:'10.5px'}}  >{value}</label>
}

const defaultColumn = {
  Cell: EditableCell,
}

function TTable({ classes, columns,className,posttoserverwithheader,updateMyData, selectedOrders, data,...rest }) {
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize,selectedRowIds },
  } = useTable(
    {
      columns,
      defaultColumn,
      data,
      updateMyData,
      selectedOrders,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) =>
          (
            <div>
            { 
            
             (row.cells[13].value === 'InsufficientFund'  || row.cells[13].value === 'In Process' ||  row.cells[13].value === 'Completed' ||  row.cells[13].value === 'Rejected')?
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              :null}
            </div>
          ),
        },
        ...columns,
      ])
    }

  )

  const [responseMessage,setResponseMessage]= useState('');
  const [responseTitle,setResponseTitle]= useState('');
  const [open, setOpen] = React.useState(false);
  const [editCodes,setEditCodes]= useState([]);


  const submitTellerRetractOrder =()=>{
    console.log(selectedFlatRows.map(
      d => {
        console.log(d.original)}
    ),);
    
    const url = domainUrl + 'Orders/RetractOrders';
    var auth = localStorage.getItem('authorization');
    var query = {  
      "OrderCodes": editCodes, 
     
    }
     
    var query2 = JSON.stringify(query);
    console.log(query2)
    /*posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth}, renderResponseModal );  */

  }

  const submitTellerCompleteOrder =()=>{
   var codes = [];
   selectedFlatRows.map(
    d =>  {
      codes.push(d.original.orderCode)}
  );

    console.log(codes);
    
    const url = domainUrl + 'Orders/CompleteOrders';
    var auth = localStorage.getItem('authorization');
    var query = {  
      "OrderCodes": codes, 
     
    }
     
    var query2 = JSON.stringify(query);
    //console.log(query2)
    posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth}, renderResponseModal );  

  }

  
    
  const submitTellerRejectOrder =()=>{
   
    var codes = [];
    
   selectedFlatRows.map(
    d =>  {
      codes.push({
        OrderCode: d.original.orderCode,
        Remark: d.original.remarks
      }); 
    }); 

    const url = domainUrl + 'Orders/RejectOrders';
    var auth = localStorage.getItem('authorization');
    var query = {  
      RejectOrderCodes:codes
     
    }
     
    var query2 = JSON.stringify(query);
    console.log(query2)
    posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth}, renderResponseModal );  
/*" */
  }

  const renderResponseModal = (data)=>{
    console.log(data)
    if(data){
     var title= 'Submit Order'
     var message = 'Order submitted successfully'
     setResponseTitle(title);
     setResponseMessage(message);
    }

    setOpen(true);
  }

    return (
      <div>
    <Button color='primary' onClick={x=> submitTellerCompleteOrder()} >Complete Orders</Button>
    <Button color='primary' onClick={x=> submitTellerRejectOrder()} >Reject Orders</Button>
    <Button color='primary' onClick={x=> submitTellerRetractOrder()} >Retract Orders</Button>
      <Card
           {...rest}
           className={clsx(classes.root, className)}
         >
           <CardContent className={classes.content}>
             <PerfectScrollbar>
               <div className={classes.inner}>
               
               <Table {...getTableProps()}>
                   <TableHead>
                         {headerGroups.map(headerGroup => (
                     <TableRow {...headerGroup.getHeaderGroupProps()}>
                       {headerGroup.headers.map(column => ( 
                         <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                           {column.render('Header')}
                           <span>
                             {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                           </span>
                         </TableCell>
                       ))}
                     </TableRow>
                   ))}
                      
                   </TableHead>
                   <TableBody  {...getTableBodyProps()}>
                     {page.map((row, i) => {
                       prepareRow(row)
                       return (
                         <TableRow 
                         className={classes.tableRow}
                         hover
                          {...row.getRowProps()}>
                           {row.cells.map(cell => {
                             return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                           })}
                         </TableRow>
                       )
                     })}
                   
                   </TableBody>
                 </Table>
     
               </div>
             </PerfectScrollbar>
           </CardContent>
           <CardActions className={classes.actions}>
             <div className="pagination">
               <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                 {'<<'}
               </button>{' '}
               <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                 {'<'}
               </button>{' '}
               <button onClick={() => nextPage()} disabled={!canNextPage}>
                 {'>'}
               </button>{' '}
               <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                 {'>>'}
               </button>{' '}
               <span>
                 Page{' '}
                 <strong>
                   {pageIndex + 1} of {pageOptions.length}
                 </strong>{' '}
               </span>
               <span>
                 | Go to page:{' '}
                 <input
                   type="number"
                   defaultValue={pageIndex + 1}
                   onChange={e => {
                     const page = e.target.value ? Number(e.target.value) - 1 : 0
                     gotoPage(page)
                   }}
                   style={{ width: '100px' }}
                 />
               </span>{' '}
               <select
                 value={pageSize}
                 onChange={e => {
                   setPageSize(Number(e.target.value))
                 }}
               >
                 {[10, 20, 30, 40, 50].map(pageSize => (
                   <option key={pageSize} value={pageSize}>
                     Show {pageSize}
                   </option>
                 ))}
               </select>
             </div>
           </CardActions>
         </Card>
         </div>
         )
}

const TellerOrdersTable = props => {
  const { className, orders,handleSubmitOrder, handleGetBanks,handleGetRates,obtainRate,banks,  ...rest } = props;
  const [modalStyle] = React.useState(getModalStyle);

  const [values, setValues] = useState({
    order_status:0
  });
 
  const classes = useStyles();
  const [responseMessage,setResponseMessage]= useState('');
  const [responseTitle,setResponseTitle]= useState('');
  const [open, setOpen] = React.useState(false);
  const [editCode,setEditCode]= useState('');
  const [selectedOrders, setSelectedOrders]= useState([]);
  const [chkBoxes, setChkBoxes]= useState([]);
  const [openModal, setModalOpen] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [theBanks, setBanks] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedOrder, setSelectedOrder]=useState(null);
  
  const columns=React.useMemo(
    () => [
    {
      "Header": "Order Code",
      "accessor": "orderCode",
      sortType: 'basic'
    },{
      "Header": "Date Time",
      "accessor": "doneOn",
      sortType: 'basic',
      Cell: props=>  {return moment(props.value).format('DD/MM/YYYY')}
    },{
        "Header": "Sender",
        "accessor": "senderContact",
        sortType: 'basic'
    },{
        "Header": "Recipient Name",
        "accessor": "recipientAccountName",
        sortType: 'basic'
    },{
        "Header": "Account",
        "accessor": "recipeintAccountNo",
        sortType: 'basic'
    },{
        "Header": "Bank",
        "accessor": "bankName",
        sortType: 'basic'
    },{
      "Header": "MYR",
      "accessor": "amountBase",
      sortType: 'basic'
    },{
      "Header": "IDR",
      "accessor": "amountExchange",
      sortType: 'basic'
    },{
      "Header": "Rate",
      "accessor": "orderRate",
      sortType: 'basic',

    },{
        "Header": "Obtain Rate",
        "accessor": "obtainedRate",
        sortType: 'basic',
  
    },{
        "Header": "Fee",
        "accessor": "orderFee",
        sortType: 'basic',
  
    },{
        "Header": "Obtain Fee",
        "accessor": "obtainedFee",
        sortType: 'basic',
  
    },{
      "Header": "Order Status",
      "accessor": "orderStatus",
      sortType: 'basic',

  },{
        "Header": "Agent",
        "accessor": "agentUsername",
        sortType: 'basic',
  
    },{
      "Header": "Remarks",
      "accessor": "remarks" 
  } /*,{
      "Header": "Action", 
      
      Cell: props =>{  
        console.log(selectedOrders)
        chkBoxes .push({'name':props.row.cells[0].value,
          value:false})
        setChkBoxes( chkBoxes);
        return ((props.row.cells[12].value === 'InsufficientFund'  || props.row.cells[12].value === 'In Process' ||  props.row.cells[12].value === 'Completed' ||  props.row.cells[12].value === 'Rejected')) ? 
        
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={getCheck(props)} onChange={handleChangeCheck} id={props.row.cells[0].value} name={props.row.cells[0].value} />}
              label=""
            /> 
          </FormGroup> 
      </FormControl>
       :
          null
      }

  }*/],[]
    );

    const getCheck =(props)=>{
      chkBoxes.find((x,y)=>{
        if(x.name === props.row.cells[0].value){
          return x.value;
        }
      }) ;
      return false;
    }

    
const updateMyData = (rowIndex, columnId, value) => {
  // We also turn on the flag to not reset the page
  //setSkipPageReset(true)
 // console.log(rowIndex, columnId,value)
  setSelectedOrders(old =>
    old.map((row, index) => {
      if (index === rowIndex) {
       // handleSubmitEditRate(rowIndex,columnId,value);
        return {
          ...old[rowIndex],
          [columnId]: value,
        }
      }
      return row
    })
  );
   
}


    const handleChangeCheck = (event) => {
      var ss = selectedOrders;
      var checked = false;
      if(ss.indexOf(event.target.name) === -1){
        ss.push( event.target.name);
        checked = true;
        chkBoxes.find((x,y)=>{
          if(x.name === event.target.name){
             x.value = true;
             console.log(x)
            setChkBoxes( chkBoxes);
          }
        }) ;
      }
      else{
        var ind = ss.indexOf(event.target.name);
        ss.splice(ind, 1);  
        checked = false;
        chkBoxes.find((x,y)=>{
          if(x.name === event.target.name){
             x.value = false;
             console.log(x)
             
            setChkBoxes( chkBoxes);
          }
        }) ;
      }
      console.log(ss)
      setSelectedOrders(ss); 
      return checked;
    };
    
  /*
  const renderModal = (code, row,theBanks)=>{ 
    console.log(theBanks)
    var bankid = banks.indexOf(row.cells[5].value);
    console.log(bankid)
    setEditCode(code)
      setValues({
        ...values,
        recipient_contact: row.cells[3].value, 
        bank_name: row.cells[5].value ,
        bank_account: row.cells[4].value, 
        my_fee_myr:parseFloat(row.cells[10].value),
        my_amount:parseFloat(row.cells[6].value),
        my_rate_idr: parseFloat(row.cells[8].value),
        my_obtainedrate_idr: parseFloat(row.cells[9].value), 
        is_express: false, 
        sender_phone: row.cells[2].value, 

      });
      setSelectedOrder({
        recipient_contact: row.cells[3].value, 
        bank_name: row.cells[5].value,
        bank_account: row.cells[4].value, 
        my_fee_myr:parseFloat(row.cells[10].value),
        my_amount:parseFloat(row.cells[6].value),
        my_rate_idr: parseFloat(row.cells[8].value),
        my_obtainedrate_idr: parseFloat(row.cells[9].value), 
        is_express: false, 
        sender_phone: row.cells[2].value, 
          
      })
    setModalOpen(true);
  }
 */
  const renderResponseModal = (data)=>{
    console.log(data)
    if(data){
     var title= 'Edit Order'
     var message = 'Order edited successfully'
     setResponseTitle(title);
     setResponseMessage(message);
    }

    setOpen(true);
  }

  const handleSelectAll = event => { 

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = orders.map(orders => orders.id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
    
  useEffect(() => {
     setSelectedOrders(orders);
     
    console.log(orders) 
  },[orders]);
  
 /*
  useEffect(() => { 
    if(selectedOrder !== null  && banks.length > 0){
      for(var b in banks){
        if(banks[b].bankName === selectedOrder.bank_name){
          var bankId = parseInt(banks[b].id);
          console.log(bankId) 
          setValues({...values, bank_name:bankId});
          handleGetRates(bankId)
          
           
        }
      }
    }
  },[selectedOrder]);

  useEffect(() => {
    if(obtainRate){
      setValues({...values, 
        my_obtainedexpress: obtainRate.obtainedExpressFee,
        my_obtainedrate_idr: obtainRate.obtainedRate,
        bank_fee_idr: obtainRate.obtainedBankFee*obtainRate.obtainedRate, 
        fee_earning:values.my_fee_myr -parseFloat(obtainRate.obtainedStandardFee) - parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0), 
        my_total:  values.my_amount+values.my_fee_myr+ parseFloat(obtainRate.obtainedStandardFee)+ 
        parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0)+parseFloat(obtainRate.obtainedExpressFee), 
        my_total_idr:  (values.my_amount+values.my_fee_myr+ parseFloat(obtainRate.obtainedStandardFee)+ 
        parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0)+parseFloat(obtainRate.obtainedExpressFee))*obtainRate.obtainedRate,
    });
    }
    
},[obtainRate]);

useEffect(() => {
  


  if(obtainRate){
    setValues({...values, 
      my_total:  values.my_amount+values.my_fee_myr+ parseFloat(obtainRate.obtainedStandardFee)+ 
parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0)+parseFloat(obtainRate.obtainedExpressFee) , 
my_total_idr:  (values.my_amount+values.my_fee_myr+ parseFloat(obtainRate.obtainedStandardFee)+ 
parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0)+parseFloat(obtainRate.obtainedExpressFee))*obtainRate.obtainedRate,
fee_earning:values.my_fee_myr -parseFloat(obtainRate.obtainedStandardFee) - parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0) ,  
    
  });
  }
  
},[values.my_fee_myr,values.my_rate_idr,values.my_amount]);
*//*
const handleChangeSwitch= event => {
  setValues({ 
    ...values,
    is_express: event.target.checked
  });
};
*/
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    if(event.target.name == 'bank_name'){ 
      setValues({
        ...values,
        [event.target.name]: parseInt(event.target.value)
      });
      handleGetRates(parseInt(event.target.value))
    } 
    if(event.target.name == 'my_fee_myr' || event.target.name == 'my_amount' || event.target.name == 'my_rate_idr' ){
      setValues({
        ...values,
        [event.target.name]: parseFloat(event.target.value)
      });
    } 
    if(event.target.name == 'my_amount'){
      setValues({
        ...values,
        [event.target.name]: parseFloat(event.target.value)
      });
    } 
    console.log(event.target.name,event.target.value )
    
  };

  return (
    <div>
     {orders.length >0 ?
     <React.Fragment>
       <TTable classes={classes} columns={columns} data={selectedOrders} posttoserverwithheader={props.posttoserverwithheader} className={className} {...rest} /*selectedOrders={selectedOrders} */updateMyData={updateMyData}  />
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">{responseTitle}</h2>
        <p id="simple-modal-description">
          {responseMessage}
        </p> 
        </div>
      </Modal>
      
      
    </React.Fragment>
   :null}
   
   </div>
    
  );
};

TellerOrdersTable.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array.isRequired,
  posttoserverwithheader: PropTypes.func,
  handleSubmitOrder: PropTypes.func,
  banks:PropTypes.array,
  selectedBank: PropTypes.string,
  obtainRate:PropTypes.object,  
 handleGetRates:  PropTypes.func,
};

export default TellerOrdersTable;
 

/*
<Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="My Express Fee"
                    margin="dense"
                    name="my_expressfee_myr"
                    onChange={handleChange}
                    required
                    disabled={!values.is_express}
                    value={values.my_expressfee_myr}
                    variant="outlined"
                  > 
                  </TextField>
                </Grid>
                */