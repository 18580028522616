import * as React from 'react';

export interface HttpPostStoreInterface {
 
  httpPostContext:{ },
  setHttpPostContext: () => {}; 
  
  postToServer :   (url:any,  queryStr:any,auth:any,  handleRetrievedData:any)   => Promise<boolean>;
  postToServerWithHeader :   (url:any, queryStr:any, header:any,handleRetrievedData:any)   => Promise<boolean>;
}

export interface HttpGetStoreInterface { 
  httpGetServiceContext:{},
  setHttpGetContext: () => {}; 
 // genericGetHttp :   (url:any, parameterizedString:any, auth:any, params:any,  handleRetrievedData:any)   => {};
  genericGetHttp :   (url:any, parameterizedString:any, auth:any, params:any )  =>Promise< any>;
  genericGetHttpBinary:(url:any, parameterizedString:any,auth:any, resType:any, params:any,handleRetrievedData:any) =>Promise<boolean>
}


export const httpPostVar = React.createContext<HttpPostStoreInterface>(null);
export const httpGetVar = React.createContext<HttpGetStoreInterface>( null);


export const HttpPostServiceContextProvider = httpPostVar.Provider;

export const HttpPostServiceContextConsumer = httpPostVar.Consumer;

export const HttpGetServiceContextProvider = httpGetVar.Provider;

export const HttpGetServiceContextConsumer = httpGetVar.Consumer;

/*
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function withHttpStoreContext<
  P extends { HttpStoreContext?: HttpStoreInterface },
  R = Omit<P, "HttpStoreContext">
>(
  Component: React.ComponentClass<P,any> | React.StatelessComponent<P>
): React.SFC<R> {
  return function BoundComponent(props: R) {
    return (
      <HttpServiceContextConsumer>
        {value => <React.Component {...props} appContext={value} />}
      </HttpServiceContextConsumer>
    );
  };
}*/

