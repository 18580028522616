import React, { useState } from 'react';
import { HttpPostServiceContextConsumer, HttpGetServiceContextConsumer } from '../../contexts/HttpServiceContext';
import { makeStyles } from '@material-ui/styles';

import { UsersToolbar, UsersTable } from './components';
import mockData from './data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = () => {
  const classes = useStyles();


  return (
    <HttpPostServiceContextConsumer> 
        {({ httpPostContext,
            setHttpPostContext,
            postToServerWithHeader  })=>
        (
          
         
       <HttpGetServiceContextConsumer>
       {({ 
               genericGetHttp  })=> 
           (
            <div className={classes.root}> 
              <UsersToolbar />
              <div className={classes.content}> 
                <UsersTable  posttoserverwithheader={postToServerWithHeader} genericgethttp={genericGetHttp} />
              </div>
            </div>
     )
    }
    </HttpGetServiceContextConsumer>
   )}
</HttpPostServiceContextConsumer>
  );
};

export default UserList;
