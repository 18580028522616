import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Modal
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const OrderEx = props => {
  const { className,handleSubmitOrder, handleGetBanks,handleGetRates, obtainRate,banks, ...rest } = props;

  const [responseMessage,setResponseMessage]= useState('');
  const [responseTitle,setResponseTitle]= useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const classes = useStyles(); 
  const [values, setValues] = useState({
    recipient_contact: '010', 
    bank_name: 'BNI',
    bank_account: '12345',
    bank_fee_idr:  6000,
    my_fee_myr: 5,
    my_amount:0,
    my_rate_idr: 2300,
    my_obtainedrate_idr: 2300,
    my_obtainedexpress: 2300,
    is_express: false, 
    sender_phone: '',
    rate_earning: 0,
    fee_earning: 0,
    my_total:0,
    my_total_idr:0,
  });
 
  useEffect(() => {
    if(obtainRate){
      setValues({...values, 
        my_obtainedexpress: obtainRate.obtainedExpressFee,
        my_obtainedrate_idr: obtainRate.obtainedRate,
        bank_fee_idr: obtainRate.obtainedBankFee*obtainRate.obtainedRate, 
        fee_earning:values.my_fee_myr -parseFloat(obtainRate.obtainedStandardFee) - parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0), 
        my_total:  values.my_amount+values.my_fee_myr+ parseFloat(obtainRate.obtainedStandardFee)+ 
        parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0)+parseFloat(obtainRate.obtainedExpressFee), 
        my_total_idr:  (values.my_amount+values.my_fee_myr+ parseFloat(obtainRate.obtainedStandardFee)+ 
        parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0)+parseFloat(obtainRate.obtainedExpressFee))*obtainRate.obtainedRate,
    });
    }
    
},[obtainRate]);

useEffect(() => {
  


  if(obtainRate){
    setValues({...values, 
      my_total:  values.my_amount+values.my_fee_myr+ parseFloat(obtainRate.obtainedStandardFee)+ 
parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0)+parseFloat(obtainRate.obtainedExpressFee) ,
my_total_idr:  (values.my_amount+values.my_fee_myr+ parseFloat(obtainRate.obtainedStandardFee)+ 
parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0)+parseFloat(obtainRate.obtainedExpressFee))*obtainRate.obtainedRate,
fee_earning:values.my_fee_myr -parseFloat(obtainRate.obtainedStandardFee) - parseFloat(obtainRate.obtainedBankFee != null ? obtainRate.obtainedBankFee: 0) ,  
    
  });
  }
  
},[values.my_fee_myr,values.my_rate_idr,values.my_amount]);

  const handleSubmitNewOrder=()=>{
    handleSubmitOrder(values);
    handleDialogClose();
  }

  const handleChangeSwitch= event => {
    setValues({ 
      ...values,
      is_express: event.target.checked
    });
  };
 
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    if(event.target.name == 'bank_name'){ 
      setValues({
        ...values,
        [event.target.name]: parseInt(event.target.value)
      });
      handleGetRates(parseInt(event.target.value))
    } 
    if(event.target.name == 'my_fee_myr' || event.target.name == 'my_amount' || event.target.name == 'my_rate_idr'){
      setValues({
        ...values,
        [event.target.name]: parseFloat(event.target.value)
      });
    } 
    if(event.target.name == 'my_amount'){
      setValues({
        ...values,
        [event.target.name]: parseFloat(event.target.value)
      });
    } 
    console.log(event.target.name,event.target.value )
    
  };
  
  
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
 
  const popUpOrderConfirmation =()=>{
    
    setResponseTitle('Place Order');
    setResponseMessage('Do you want to place this order?');
    setDialogOpen(true);
  }
  return (
    
    <React.Fragment>
    <Modal
    open={dialogOpen}
    onClose={handleDialogClose}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader=""
          title={responseTitle}
        />
        <Divider />
        <CardContent>
       
    <p id="simple-modal-description">  {responseMessage}</p> 
    </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={x=> handleSubmitNewOrder()}
          >
            Place New Order 
          </Button>
        </CardActions>
      </form>
    </Card>
  </Modal>

    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="Place new order"
          title="Place Order"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify recipient contact"
                label="Recipient Contact"
                margin="dense"
                name="recipient_contact"
                onChange={handleChange}
                required
                value={values.recipient_contact}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Select Bank"
                margin="dense"
                name="bank_name"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.bank_name}
                variant="outlined"
              >
                <option
                    key={-1}
                    value={'-1'}
                  >
                    {'Please Select.. '}
                  </option>
                {banks.map(option => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.bankName}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Sender Contact"
                margin="dense"
                name="sender_phone"
                onChange={handleChange}
                required
                
                value={values.sender_phone}
                variant="outlined"
              > 
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Bank Account"
                margin="dense"
                name="bank_account"
                onChange={handleChange}
                required
                
                value={values.bank_account}
                variant="outlined"
              > 
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="My Fee"
                margin="dense"
                name="my_fee_myr"
                onChange={handleChange}
                required
                
                value={values.my_fee_myr}
                variant="outlined"
              > 
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Bank Fee (IDR)"
                margin="dense"
                name="bank_fee_idr" 
                required
                disabled
                
                value={values.bank_fee_idr}
                variant="outlined"
              > 
              </TextField>
            </Grid>
            
          </Grid>  
          <Grid
            container
            spacing={4}
          >
             <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Amount"
                margin="dense"
                name="my_amount"
                onChange={handleChange}
                required
                
                value={values.my_amount}
                variant="outlined"
              > 
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="My Rate"
                margin="dense"
                name="my_rate_idr"
                onChange={handleChange}
                required
                
                value={values.my_rate_idr}
                variant="outlined"
              > 
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormGroup row>
                <FormControlLabel
                  control={<Switch
                          checked={values.is_express}
                          onChange={handleChangeSwitch}
                          name="is_express"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        }
                        label="Is Express"
                      /> 
            </FormGroup>
              
               
            </Grid>
             
          </Grid>

          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="My Rate Earning (MYR)"
                margin="dense"
                name="rate_earning"  
                disabled
                value={values.rate_earning}
                variant="outlined"
              > 
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="My Fee Earning (MYR)"
                margin="dense"
                name="fee_earning"
                disabled
                value={values.fee_earning}
                variant="outlined"
              > 
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
          >
             <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Total (MYR)"
                margin="dense"
                name="my_total" 
                required
                disabled
                
                value={values.my_total}
                variant="outlined"
              > 
              </TextField>
            </Grid>
            
          </Grid>

          <Grid
            container
            spacing={4}
          >
             <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Total (IDR)"
                margin="dense"
                name="my_total_idr" 
                required
                disabled
                
                value={values.my_total_idr}
                variant="outlined"
              > 
              </TextField>
            </Grid>
            
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={popUpOrderConfirmation}
          >
            Place Order
          </Button>
        </CardActions>
      </form>
    </Card>
    
    </React.Fragment>
  );
};

OrderEx.propTypes = {
  className: PropTypes.string,
  handleSubmitOrder: PropTypes.func,
  banks:PropTypes.array,
  obtainRate:PropTypes.object,  
 handleGetRates:  PropTypes.func,
};

export default OrderEx;


/*

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
           
 <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Country"
                margin="dense"
                name="country"
                onChange={handleChange}
                required
                value={values.country}
                variant="outlined"
              />
            </Grid>

            */