import React, { useState ,useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Typography
} from '@material-ui/core';

import {domainUrl} from '../../../../constants';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import mockData from './data';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestProducts = props => {
  const { className,posttoserverwithheader, ...rest } = props;

  const classes = useStyles();
 
  //const [products, setProducts] = useState([]);
  //const [spreadedProduct, setSpreadedProducts] = useState(null);
  const [spread, setSpread] = useState(null);
/*
  const handleGetRate = event => {
    const url = domainUrl + 'Products/GetProductRate';
    var auth = localStorage.getItem('authorization');
    var query = {  
     "ProductName": "MYR/IDR",
     "ProductId" : 1
   }

   var query2 = JSON.stringify(query);
   console.log(auth)
   posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleResponseRate );  
 };
*/
 const handleGetSpread = event => {
  const url = domainUrl + 'Spreads/GetObtainedSpread';
  var auth = localStorage.getItem('authorization');
  var query = {   
   "ProductId" : 1
 }

 var query2 = JSON.stringify(query);
 console.log(auth)
 posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleResponseSpread );  
};
  
  
/*
useEffect(() => { 
  if(products.length > 0 && spread !== null && spread !== undefined){

    setSpreadedProducts({
      id : products[0].id,
      name : products[0].productname,
      rate: parseFloat(products[0].currentrate ) - parseFloat(spread)
    });
  }
},[products,spread]);*/
 useEffect(() => { 
  //handleGetRate();
  handleGetSpread();
},[]);

 /*const handleResponseRate =(data)=>{
  if(data.data){
    setProducts(data.data);
  }
   
 }
 */
 const handleResponseSpread =(data)=>{
   console.log(data)
    if(data.data){
      setSpread(data.data);
    }
 }
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        subtitle={``}
        title="Latest Rate"
      />
      <Divider />
      <CardContent className={classes.content}> 
      {spread !== null ?<List>
         <ListItem
              divider={false}
              key={spread.productId}
            >
               <Typography variant="h3"> {spread.obtainedRate}  </Typography>
             
            </ListItem>
             
        </List>
        : null}
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
          href="/setrate"
        >
          Set Downline Rate <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestProducts.propTypes = {
  className: PropTypes.string
};

export default LatestProducts;
