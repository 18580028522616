import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {domainUrl} from '../../../../constants';
import { HttpPostServiceContextConsumer, HttpGetServiceContextConsumer } from '../../../../contexts/HttpServiceContext';
import { makeStyles } from '@material-ui/styles';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination
  
} from 'react-table';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Typography,
  Modal ,
  TablePagination
} from '@material-ui/core';

import { getInitials } from '../../../../helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow:'auto'
  };
}

function TTable({ classes, columns,className, data,updateMyData,handleSubmitEditRate,originalData, ...rest }) {
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      defaultColumn,
      data,
      initialState: { pageIndex: 0 },
      updateMyData,
      handleSubmitEditRate,
      originalData
    },
    useSortBy,
    usePagination,
  )

  function compareObjects(o, p)
  {
      var i,
          keysO = Object.keys(o).sort(),
          keysP = Object.keys(p).sort();
      if (keysO.length !== keysP.length)
          return false;//not the same nr of keys
      if (keysO.join('') !== keysP.join(''))
          return false;//different keys
      for (i=0;i<keysO.length;++i)
      {
          if (o[keysO[i]] instanceof Array)
          {
              if (!(p[keysO[i]] instanceof Array))
                  return false;
              //if (compareObjects(o[keysO[i]], p[keysO[i]] === false) return false
              //would work, too, and perhaps is a better fit, still, this is easy, too
              if (p[keysO[i]].sort().join('') !== o[keysO[i]].sort().join(''))
                  return false;
          }
          else if (o[keysO[i]] instanceof Date)
          {
              if (!(p[keysO[i]] instanceof Date))
                  return false;
              if ((''+o[keysO[i]]) !== (''+p[keysO[i]]))
                  return false;
          }
          else if (o[keysO[i]] instanceof Function)
          {
              if (!(p[keysO[i]] instanceof Function))
                  return false;
              //ignore functions, or check them regardless?
          }
          else if (o[keysO[i]] instanceof Object)
          {
              if (!(p[keysO[i]] instanceof Object))
                  return false;
              if (o[keysO[i]] === o)
              {//self reference?
                  if (p[keysO[i]] !== p)
                      return false;
              }
              else if (compareObjects(o[keysO[i]], p[keysO[i]]) === false)
                  return false;//WARNING: does not deal with circular refs other than ^^
          }
          if (o[keysO[i]] !== p[keysO[i]])//change !== to != for loose comparison
              return false;//not the same value
      }
      return true;
  }
  const handleSubmitUpdateSpread =()=>{ 
    
  var compared =  originalData.map(o=> 
    { 
      var updated=[];
      if(!compareObjects(o,data[originalData.indexOf(o)])){
        updated.push(data[originalData.indexOf(o)]);
      } 
      return updated;

    });

    var compiled = [];
    compared.map(c=>{
      if(c.length > 0){

        console.log(c)
        compiled.push(c);
        handleSubmitEditRate(c)
      }
    })

    console.log(compiled)
   /* console.log(selectedFlatRows.map(
      d => d.original
    ),);
    const url = domainUrl + 'Orders/CompleteOrder';
    var auth = localStorage.getItem('authorization');
    var query = {  
      "OrderCode": orderCode, 
    }
     
    var query2 = JSON.stringify(query);

   // handleSubmitEditRate(rowIndex,columnId,value);
    //console.log(query2)
   /* posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth}, renderResponseModal );  */

  }

    return (
      <div>
      <Button color='primary' onClick={x=> handleSubmitUpdateSpread()} >Update Rate</Button>
    
      <Card
           {...rest}
           className={clsx(classes.root, className)}
         >
           <CardContent className={classes.content}>
             <PerfectScrollbar>
               <div className={classes.inner}>
               
               <Table {...getTableProps()}>
                   <TableHead>
                         {headerGroups.map(headerGroup => (
                     <TableRow {...headerGroup.getHeaderGroupProps()}>
                       {headerGroup.headers.map(column => ( 
                         <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                           {column.render('Header')}
                           <span>
                             {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                           </span>
                         </TableCell>
                       ))}
                     </TableRow>
                   ))}
                      
                   </TableHead>
                   <TableBody  {...getTableBodyProps()}>
                     {page.map((row, i) => {
                       prepareRow(row)
                       return (
                         <TableRow 
                         className={classes.tableRow}
                         hover
                          {...row.getRowProps()}>
                           {row.cells.map(cell => {
                             return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                           })}
                         </TableRow>
                       )
                     })}
                   
                   </TableBody>
                 </Table>
     
               </div>
             </PerfectScrollbar>
           </CardContent>
           <CardActions className={classes.actions}>
             <div className="pagination">
               <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                 {'<<'}
               </button>{' '}
               <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                 {'<'}
               </button>{' '}
               <button onClick={() => nextPage()} disabled={!canNextPage}>
                 {'>'}
               </button>{' '}
               <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                 {'>>'}
               </button>{' '}
               <span>
                 Page{' '}
                 <strong>
                   {pageIndex + 1} of {pageOptions.length}
                 </strong>{' '}
               </span>
               <span>
                 | Go to page:{' '}
                 <input
                   type="number"
                   defaultValue={pageIndex + 1}
                   onChange={e => {
                     const page = e.target.value ? Number(e.target.value) - 1 : 0
                     gotoPage(page)
                   }}
                   style={{ width: '100px' }}
                 />
               </span>{' '}
               <select
                 value={pageSize}
                 onChange={e => {
                   setPageSize(Number(e.target.value))
                 }}
               >
                 {[10, 20, 30, 40, 50].map(pageSize => (
                   <option key={pageSize} value={pageSize}>
                     Show {pageSize}
                   </option>
                 ))}
               </select>
             </div>
           </CardActions>
         </Card>
         </div>
         )
}

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  if(   id !== 'downlineUsername')
  return <input value={value} onChange={onChange} onBlur={onBlur} style={{paddingTop:'10.5px', paddingBottom:'10.5px'}} />
  else
  return  <input value={value} disabled style={{paddingTop:'10.5px', paddingBottom:'10.5px'}}  />
}


const defaultColumn = {
  Cell: EditableCell,
}
const DownlineRateTable = props => {
  const { className,  ...rest } = props;

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [ownInfo, setOwnInfo] = React.useState({});
  const [users, setUsers] = React.useState([]);
  const [originalUsers, setOriginalUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [responseMessage,setResponseMessage]= useState('');
  const [responseTitle,setResponseTitle]= useState('');
  const [open, setOpen] = React.useState(false);
  
  

  const handleCheckIsLoggedIn =()=>{
      
    const url = domainUrl + 'Auth/IsLoggedIn';
    var auth = localStorage.getItem('authorization');
    var query = {  
      'Authorization': auth 
    }

    var query2 = JSON.stringify(query);
    props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleResponseIsLoggedIn );  

  }
  

const updateMyData = (rowIndex, columnId, value) => {
  // We also turn on the flag to not reset the page
  //setSkipPageReset(true)
  console.log(rowIndex, columnId,value)
  setUsers(old =>
    old.map((row, index) => {
      if (index === rowIndex) {
       // handleSubmitEditRate(rowIndex,columnId,value);
        return {
          ...old[rowIndex],
          [columnId]: value,
        }
      }
      return row
    })
  );
   
}


  const handleResponseIsLoggedIn = (data) =>{
    // handleGetFirstTreeNodes(data.data)

    setOwnInfo(data.data)
   }

   
  const handleGetDownlines =()=>{
    const url = domainUrl + 'Spreads/ListSpreadTable';
    var auth = localStorage.getItem('authorization');
    var query = {  
      'ProductId':1,
      'Pagination': {
        'PageSize': rowsPerPage,
        'PageNum': page
      } 
    }

    var query2 = JSON.stringify(query);
    props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth}, handlePopulateTable );
  }
  
  const handleSubmitEditRate =(data)=>{
      //var data = users[rowIndex];
       console.log(data); 
      var user = data.downlineUsername;
      var feeSpread = 0.0;
      var rateSpread = 0.0;
      var expressFeeSpread = 0.0;
      rateSpread = parseFloat(data[0].uplineRateSpread);
      feeSpread = parseFloat(data[0].uplineStandardFeeSpread);
      expressFeeSpread = parseFloat(data[0].uplineExpressFeeSpread);
/*      if(columnId === 'uplineRateSpread'){
      }
      if(columnId === 'uplineStandardFeeSpread'){
      }
      if(columnId === 'uplineExpressFeeSpread'){
      }
*/
    const url = domainUrl + 'Spreads/UpdateDownlineSpread';
    var auth = localStorage.getItem('authorization'); 
    var query = {  
      'ProductId':1,
      'Username':user,
      'UplineRateSpread':rateSpread,
      'UplineStandardFeeSpread': feeSpread,
      'UplineExpressFeeSpread': expressFeeSpread 
    }

    var query2 = JSON.stringify(query);
    console.log(query2);
    //props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleSubmitResponse );  

  }

  
  const handleSubmitResponse = (data) =>{
    // handleGetFirstTreeNodes(data.data) 
      if(data){
         console.log('OK')
      }
   }


  const handlePopulateTable = (data) =>{
    // handleGetFirstTreeNodes(data.data)
      console.log(data.data)
      if(data.data){
        
        setUsers(data.data)
        setOriginalUsers(data.data)
      }
   }

 


  useEffect(() => {
    handleCheckIsLoggedIn();
    handleGetDownlines();
},[]);
  const columns=React.useMemo(
    () => [
    {
      "Header": "Downline User",
      "accessor": "downlineUsername",
      sortType: 'basic'
    },{
      "Header": "Rate",
      "accessor": "uplineRateSpread",
      sortType: 'basic'
    },{
      "Header": "Fee",
      "accessor": "uplineStandardFeeSpread",
      sortType: 'basic'
    },{
      "Header": "Express Fee",
      "accessor": "uplineExpressFeeSpread",
      sortType: 'basic'
    }],[]
    );
  const handleSelectAll = event => { 

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.map(user => user.id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  
  const renderResponseModal = (data)=>{
    console.log(data)
    if(data){
     var title= 'Edit Order'
     var message = 'Order edited successfully'
     setResponseTitle(title);
     setResponseMessage(message);
    }

    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (

    <React.Fragment>
      <TTable classes={classes} columns={columns} data={users} className={className} {...rest}    updateMyData={updateMyData} handleSubmitEditRate={handleSubmitEditRate} originalData={originalUsers}/>
      <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">{responseTitle}</h2>
      <p id="simple-modal-description">
        {responseMessage}
      </p> 
      </div>
    </Modal>
  </React.Fragment>
  
  );
}; 


const HOCDownlineRateTable = (props) => {  
  return (
    
<HttpPostServiceContextConsumer> 
    {({ httpPostContext,
        setHttpPostContext,
        postToServerWithHeader  })=>
    (
      <React.Fragment> 
        <DownlineRateTable {...props} posttoserverwithheader={postToServerWithHeader}  sethttppostcontext={setHttpPostContext} httppostcontext={httpPostContext}></DownlineRateTable>
       </React.Fragment>
    )}
</HttpPostServiceContextConsumer>
  );
}


DownlineRateTable.propTypes = {
 className: PropTypes.string,
 postToServer: PropTypes.func,
 sethttppostcontext:  PropTypes.func,
 httppostcontext:  PropTypes.any,
};
export default HOCDownlineRateTable;
 




/*




                {users.slice(0, rowsPerPage).map(user => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                    selected={selectedUsers.indexOf(user.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.indexOf(user.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, user.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={user.avatarUrl}
                        >
                          {getInitials(user.name)}
                        </Avatar>
                        <Typography variant="body1">{user.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.address.city}, {user.address.state},{' '}
                      {user.address.country}
                    </TableCell>
                    <TableCell>{user.phone}</TableCell>
                    <TableCell>
                      {moment(user.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                  </TableRow>
                ))}

        /*<TablePagination
                  component="div"
                  count={users.length}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
            
            <EnhancedTable
              data={users}
              columns={cols}
              showPagination={true}
              defaultPageSize={10}
              resizable={true}
              className={classes}
            />
            */