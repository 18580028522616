import * as React from 'react'; 
import axios from 'axios';
import { HttpGetServiceContextProvider, HttpGetStoreInterface } from '../contexts/HttpServiceContext';

function get(url, auth , params  ) {
    return axios.get(url,{auth:auth, params:params});
}
function getBuffer(url, auth , resType, params  ) {
    return axios.get(url,{auth:auth,responseType:resType, params:params});
}
function get2(url,   params={}  ) {
    return axios.get(url, params );
}

export class HttpGetService extends React.Component<any,any>{
    
    constructor(props: any) {
        super(props); 
        this.state={ 
            httpGetServiceContext:{},
            setHttpGetContext : this.setHttpGetContext, 
            genericGetHttp:this.genericGetHttp2,
            genericGetHttpBinary:this.genericGetHttpBinary,
        }
    }

    getServerDataByPage(page, pageSize, sorted, filtered, handleRetrievedData) {
        let url = this.state.baseURL + this.state.subURL;
        let postObject = {
            page: page,
            pageSize: pageSize,
            sorted: sorted,
            filtered: filtered,
        }; 

        return get2(url, postObject).then(response => handleRetrievedData(response)).catch(response => console.log(response));
    }
    
    setHttpGetContext = httpGetServiceContext => {
        // console.log(mainContext)
         this.setState({ httpGetServiceContext });
       };
 
    setBaseUrl = (baseURL,subURL) => {
      //  console.log(baseURL)
        this.setState({ baseURL:baseURL,subURL:subURL });
    };

   async genericGetHttp(url, parameterizedString,auth, params,handleRetrievedData){
        let that = this; 
       // let url = that.state.baseURL + that.state.subURL;  
       // console.log(this.state);
       if(parameterizedString !== null && parameterizedString !== '')
        url = url + '?'+parameterizedString;
        
       // console.log(url);
        return get2(url,params).then(
            response=>{
                var data = response;
                handleRetrievedData(data)
            }
        )
        .catch(response => console.log(response));
    }

    
    genericGetHttp2(url, parameterizedString,auth, params){
    let that = this; 
   // let url = that.state.baseURL + that.state.subURL;  
   // console.log(this.state);
   if(parameterizedString !== null && parameterizedString !== '')
    url = url + '?'+parameterizedString;
                               
   // console.log(url);
     return  get2(url,params)
      /*.then(
        response=>{
            return  response
        }
    )
    .catch(response => console.log(response)); */
}

    
    genericGetHttpBinary(url, parameterizedString,auth, resType, params,handleRetrievedData){
        let that = this; 
       // let url = that.state.baseURL + that.state.subURL;  
       // console.log(this.state);
        url = url + '?'+parameterizedString;
       // console.log(url);
        return getBuffer(url,auth,resType, params).then(
            response=>{
                var data = response.data;
                // console.log(response);
                handleRetrievedData(data)
            }
        )
        .catch(response => console.log(response));
    }

      searchGeocodeAndAddresses(url,searchTxt, appId, appCode,handleRetrievedData){
       // let url = this.state.baseURL + this.state.subURL;
        let postObject = {
            searchtext: searchTxt,
            app_id: appId,
            app_code: appCode,
            gen: 8,
        }; 
        url = url + '?searchtext='+postObject.searchtext + '&app_id='+postObject.app_id + '&app_code='+postObject.app_code + '&gen='+ postObject.gen;
        return get2(url).then(
            response=>{
                var data = response.data.Response;
                //console.log(response);
                handleRetrievedData(data)
            }
        )
        .catch(response => console.log(response));
    }
    

    render(){ 
          return <HttpGetServiceContextProvider  value={ { 
            httpGetServiceContext:this.state.httpGetServiceContext,
            setHttpGetContext : this.state.setHttpGetContext, 
            genericGetHttp:this.state.genericGetHttp,
            genericGetHttpBinary:this.state.genericGetHttpBinary,
        } }>
                    { this.props.children}
                </HttpGetServiceContextProvider>  ;
    }
}