import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Footer = props => {
  const { className,footerLinkText,footerLink,copyrightYear, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant="body1">
        &copy;{' '}
        <Link
          component="a"
          href={footerLink}
          target="_blank"
        >
          {footerLinkText}
        </Link>
       &nbsp;. &nbsp;{copyrightYear}
      </Typography>
      <Typography variant="caption">
        Created for extensible and fun!
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  footerLink: PropTypes.string,
  footerLinkText: PropTypes.string,
  copyrightYear: PropTypes.number,

};

export default Footer;
