import * as React from 'react'; 
import axios from 'axios'; 
import { HttpPostServiceContextProvider } from '../contexts/HttpServiceContext';
function post2(url,auth, params = {}) {
    return  axios({
        method: 'post',
        url:url,
        data:params,
        auth: auth,
      headers: {'Content-Type': 'application/json'}
      }) ;
}
function postWithHeader(url, params = {}, header) {
    return  axios({
        method: 'post',
        url:url,
        data:params,
        headers: header
      }) ;
}

function post3(url, params ) {
    return   axios.post(
        url,
        params
    ) ;
}

export class HttpPostService extends React.Component<any,any>{
    
    constructor(props: any) {
        super(props); 
        this.state={ 
            setHttpContext : this.setHttpContext,
            postToServer:this.postToServer, 
            postToServerWithHeader:this.postToServerWithHeader,
            httpServiceContext:{},
        }
    }
 

    static getDerivedStateFromProps(props, state) {
        var update :any = {};
 
        return update;
    }

    async retrieveSettingFromServer(queryStr, handleRetrievedData){
        let url = this.state.baseURL + this.state.subURL;
        //console.log(url)
        //console.log(queryStr)
        return this.post(url, queryStr).then(
            response=>{
                handleRetrievedData(response)
            }
        )
        .catch(response => console.log(response));
    }
 
/*
    async retrieveActiveTiledLayersFromServer(layerName, layerStyles,handleRetrievedData){
        let url = this.state.baseURL + this.state.subURL;
        return this.post(url, queryStr).then(
            response=>{
                var data = response.data.data;
                console.log(data);
                handleRetrievedData(data)
            }
        )
        .catch(response => console.log(response));
    }
*/


  postToServer(url, queryStr,auth,handleRetrievedData){
  //  let url = this.state.basePURL + this.state.subPURL;
    //console.log(queryStr);
    return  post3(url, queryStr).then(
        response=>{
            var data = response.data;
            //console.log(data);
            handleRetrievedData(data)
        }
    )
    .catch(response => 
        {alert ('Invalid Captcha or login information') ; console.log(response)});
}

    

  postToServerWithHeader(url, queryStr,header,handleRetrievedData){
    //  let url = this.state.basePURL + this.state.subPURL;
      console.log(queryStr);
      return  postWithHeader(url, queryStr,header).then(
          response=>{
              var data = response;
              //console.log(data);
              handleRetrievedData(data)
          }
      )
      .catch(response => console.log(response));
  }
  
    async initializeAppsFromServerSession(queryStr, handleRetrievedData){
        let url = this.state.baseURL + this.state.subURL;
        return this.post(url, queryStr).then(
            response=>{
                handleRetrievedData(response)
            }
        )
        .catch(response => console.log(response));
    }

    
    async initializeAppsFromServerSession2(url, queryStr, handleRetrievedData){ 
        return this.post(url, queryStr).then(
            response=>{
                handleRetrievedData(response)
            }
        )
        .catch(response => console.log(response));
    }
 
    setHttpContext = httpServiceContext => {
        // console.log(mainContext)
        this.setState({ httpServiceContext });
    };

    post(url, params = {}) {
        return axios.post(url, params)
    }

    
    render(){
        const{httpServiceContext} = this.state;
        return <HttpPostServiceContextProvider  value={ {
            httpPostContext:  httpServiceContext,
            setHttpPostContext:this.state.setHttpPostContext,
            postToServer: this.state.postToServer,
            postToServerWithHeader: this.state.postToServerWithHeader  }}>
                {this.props.children}
        </HttpPostServiceContextProvider>  
        ;
    }
}