import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid,Modal,Card,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  Button,

 } from '@material-ui/core';
import { HttpPostServiceContextConsumer, HttpGetServiceContextConsumer } from '../../contexts/HttpServiceContext';
import {domainUrl} from '../../constants';


import  { OrderEx,ProductInfo}   from './components';
//import mockData from './data';
import OrdersTable from './components/OrderListTable/OrdersTable';

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const OrderList = (props) => {
  const classes:any = useStyles();
  const [orders,setOrders] = useState([]);
  const [banks, setBanks]=useState([]);
  const [rates, setRate]=useState(null);
  const [downlines, setDownlines] = React.useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [responseTitle,setTitle]= useState('');
  const [responseMessage,setResponseMessage]= useState('');
  const [statusCallBack,setStatusCallBack]= useState('');

  
  const handleCheckPendingOrder =(status)=>{
      
    const url = domainUrl + 'Orders/ListDirectGroupOrderTable';
    var auth = localStorage.getItem('authorization');
    var query = {  
      "ProductId": 1,
      //"AgentUsername":localStorage.getItem('user'),
      //"OrderStatus":status, 
      'Authorization': auth ,
      "Pagination":{
        "OrderByKeys":null,
        "PageSize":50,
        "PageNum":1
      }
    }
    var query2 = JSON.stringify(query);
    props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleResponsePendingOrder );  

  }

  const handleResponsePendingOrder =(data)=>{
    if(data.data){

      setOrders(data.data); 
    }
  }

  const handleSelect = (event, nodeIds) => {
    setOrders(nodeIds);
  };
 
useEffect(() => {
  handleCheckPendingOrder('Pending');
  
},[]);

const handleGetBanks = () => {
  const url = domainUrl + 'Banks/GetActiveBanks';
  var auth = localStorage.getItem('authorization');
  var query = {  
    "Pagination":{
      "OrderByKeys":null,
      "PageSize":50,
      "PageNum":0
    }
 }

 var query2 = JSON.stringify(query);
 console.log(auth)
 return props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleBankResponse
);  
};

const handleBankResponse =(data)=>{ 
  setBanks(data.data);
}

const handleRate =(data)=>{
  setRate(data.data);
}


useEffect(() => {
  handleGetBanks();
  handleGetRates();
  handleGetDownlines();
},[]);



const handleGetRates  = (bankid = 0) => {
const url = domainUrl + 'Spreads/GetObtainedSpread';
var auth = localStorage.getItem('authorization');
var query = {   
  "ProductId" : 1,
  "BankId":bankid 
}

var query2 = JSON.stringify(query);
console.log(auth)
return props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleRate
);  
};

const handleSetDownlines = (data)=>{
  if(data.data){
    setDownlines(data.data);
  }
}
const handleRetractResponse = (data)=>{
  console.log(data)
  if(data.data){
    setDialogOpen(true);
    setTitle('Retract Order Successfully');
    setResponseMessage('Retract Order Successfully');
    setStatusCallBack(data.data);

  }
  else{
    
    setDialogOpen(true);
    setTitle('Retract Order Failed');
    setResponseMessage('Retract Order Failed');
    setStatusCallBack(data.data);
  }
}


const handleRetractOrder  = (ordercode) => {
  const url = domainUrl + 'Orders/RetractOrder';
    var auth = localStorage.getItem('authorization');
    var query = {  
      'OrderCode': ordercode
    }
    var query2 = JSON.stringify(query);
    props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleRetractResponse );  


  };
const handleDialogClose = ()=>{
  setDialogOpen(false);
}
const handleGetDownlines  = (bankid = 0) => {
  const url = domainUrl + 'Users/ListDirectDownlineUserTable';
    var auth = localStorage.getItem('authorization');
    var query = {  
      'Pagination': {
        'PageSize': 100,
        'PageNum': 1
      } 
    }
    var query2 = JSON.stringify(query);
    props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleSetDownlines );  


  };

  return (
    <HttpPostServiceContextConsumer> 
      {({ httpPostContext,
          setHttpPostContext,
          postToServerWithHeader  })=>
      (
    <div className={classes.root}> 
      <div className={classes.content}>
        <OrdersTable statusCallBack={statusCallBack} orders={orders} posttoserverwithheader={postToServerWithHeader} downlines={downlines}  banks={banks} obtainRate={rates}   handleGetRates={handleGetRates} handleRetractOrder={handleRetractOrder} />
        <Modal
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Card  
        >
          <form
            autoComplete="off"
            noValidate
          >
            <CardHeader
              subheader=""
              title={responseTitle}
            />
            <Divider />
            <CardContent>
           
        <p id="simple-modal-description">  {responseMessage}</p> 
        </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                onClick={x=> handleDialogClose()}
              >
                OK
              </Button>
            </CardActions>
          </form>
        </Card>
      </Modal>

      </div>
    </div>
    )}
    </HttpPostServiceContextConsumer>
  );
};

const HOCOrderList =()=>{
  
  return (<HttpPostServiceContextConsumer> 
        {({ httpPostContext,
            setHttpPostContext,
            postToServerWithHeader  })=>
        (
          
         
       <HttpGetServiceContextConsumer>
       {({ 
               genericGetHttp  })=> 
           (
             <OrderList  posttoserverwithheader={postToServerWithHeader} genericgethttp={genericGetHttp}/>
            )
        }
        </HttpGetServiceContextConsumer>
        )}
    </HttpPostServiceContextConsumer>
  );
}

export default HOCOrderList;
