import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {domainUrl} from '../../../../constants';
import { makeStyles } from '@material-ui/styles';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
} from 'react-table';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import { getInitials } from '../../../../helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

function TTable({ classes, columns,className, data,...rest }) {
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
  )


    return (
      <Card
           {...rest}
           className={clsx(classes.root, className)}
         >
           <CardContent className={classes.content}>
             <PerfectScrollbar>
               <div className={classes.inner}>
               
               <Table {...getTableProps()}>
                   <TableHead>
                         {headerGroups.map(headerGroup => (
                     <TableRow {...headerGroup.getHeaderGroupProps()}>
                       {headerGroup.headers.map(column => ( 
                         <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                           {column.render('Header')}
                           <span>
                             {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                           </span>
                         </TableCell>
                       ))}
                     </TableRow>
                   ))}
                      
                   </TableHead>
                   <TableBody  {...getTableBodyProps()}>
                     {page.map((row, i) => {
                       prepareRow(row)
                       return (
                         <TableRow 
                         className={classes.tableRow}
                         hover
                          {...row.getRowProps()}>
                           {row.cells.map(cell => {
                             return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                           })}
                         </TableRow>
                       )
                     })}
                   
                   </TableBody>
                 </Table>
     
               </div>
             </PerfectScrollbar>
           </CardContent>
           <CardActions className={classes.actions}>
             <div className="pagination">
               <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                 {'<<'}
               </button>{' '}
               <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                 {'<'}
               </button>{' '}
               <button onClick={() => nextPage()} disabled={!canNextPage}>
                 {'>'}
               </button>{' '}
               <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                 {'>>'}
               </button>{' '}
               <span>
                 Page{' '}
                 <strong>
                   {pageIndex + 1} of {pageOptions.length}
                 </strong>{' '}
               </span>
               <span>
                 | Go to page:{' '}
                 <input
                   type="number"
                   defaultValue={pageIndex + 1}
                   onChange={e => {
                     const page = e.target.value ? Number(e.target.value) - 1 : 0
                     gotoPage(page)
                   }}
                   style={{ width: '100px' }}
                 />
               </span>{' '}
               <select
                 value={pageSize}
                 onChange={e => {
                   setPageSize(Number(e.target.value))
                 }}
               >
                 {[10, 20, 30, 40, 50].map(pageSize => (
                   <option key={pageSize} value={pageSize}>
                     Show {pageSize}
                   </option>
                 ))}
               </select>
             </div>
           </CardActions>
         </Card>
         )
}

const UsersTable = props => {
  const { className,  ...rest } = props;

  const classes = useStyles();

  const [ownInfo, setOwnInfo] = React.useState({});
  const [users, setUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const handleCheckIsLoggedIn =()=>{
      
    const url = domainUrl + 'Auth/IsLoggedIn';
    var auth = localStorage.getItem('authorization');
    var query = {  
      'Authorization': auth 
    }
    var query2 = JSON.stringify(query);
    props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleResponseIsLoggedIn );  

  }
  
  const handleResponseIsLoggedIn = (data) =>{
    // handleGetFirstTreeNodes(data.data)

    setOwnInfo(data.data)
   }

   
  const handleGetDownlines =()=>{
      
    const url = domainUrl + 'Users/ListDirectDownlineUserTable';
    var auth = localStorage.getItem('authorization');
    var query = {  
      'Pagination': {
        'PageSize': rowsPerPage,
        'PageNum': page
      } 
    }
    var query2 = JSON.stringify(query);
    props.posttoserverwithheader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handlePopulateTable );  

  }
  const handlePopulateTable = (data) =>{
    // handleGetFirstTreeNodes(data.data)
console.log(data.data)
if(data.data){
  
  setUsers(data.data)
}
   }

 


  useEffect(() => {
    handleCheckIsLoggedIn();
    handleGetDownlines();
},[]);
  const columns=React.useMemo(
    () => [
    {
      "Header": "User",
      "accessor": "username",
      sortType: 'basic'
    },{
      "Header": "Email",
      "accessor": "email",
      sortType: 'basic'
    },{
      "Header": "Language",
      "accessor": "language",
      sortType: 'basic'
    },{
      "Header": "Phone",
      "accessor": "contactNo",
      sortType: 'basic'
    },{
      "Header": "Status",
      "accessor": "status",
      sortType: 'basic'
    },{
      "Header": "Networth",
      "accessor": "currentNetworth",
      sortType: 'basic'
    },{
      "Header": "Registration date",
      "accessor": "createdOn",
      sortType: 'basic',
      Cell: props=>  {return moment(props.value).format('DD/MM/YYYY')}

    }],[]
    );
  const handleSelectAll = event => { 

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.map(user => user.id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (

    
    <TTable classes={classes} columns={columns} data={users} className={className} {...rest} />
   
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;


/*




                {users.slice(0, rowsPerPage).map(user => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                    selected={selectedUsers.indexOf(user.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.indexOf(user.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, user.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={user.avatarUrl}
                        >
                          {getInitials(user.name)}
                        </Avatar>
                        <Typography variant="body1">{user.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.address.city}, {user.address.state},{' '}
                      {user.address.country}
                    </TableCell>
                    <TableCell>{user.phone}</TableCell>
                    <TableCell>
                      {moment(user.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                  </TableRow>
                ))}

        /*<TablePagination
                  component="div"
                  count={users.length}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
            
            <EnhancedTable
              data={users}
              columns={cols}
              showPagination={true}
              defaultPageSize={10}
              resizable={true}
              className={classes}
            />
            */