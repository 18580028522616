import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  LinearProgress
} from '@material-ui/core';

const useStyles = makeStyles((theme:any) => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const ProductInfo = props => {
  const { className, obtainRate, ...rest } = props;

  const classes:any = useStyles();

  const [prod, setProd] =useState({
    product: 'MYR/IDR',
    current_rate: 1000,
    updatedAt: '', 
  });

  useEffect(() => {
    if(obtainRate){
      setProd({ 
            ...prod,
            current_rate: obtainRate.obtainedRate
        });
    }
    
},[obtainRate]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography
              gutterBottom
              variant="h2"
            >
              {prod.product}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              
              {prod.current_rate}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              {moment().format('hh:mm A')} ({prod.updatedAt})
            </Typography>
          </div> 
        </div>
      </CardContent>
      <Divider />
      <CardActions>
      </CardActions>
    </Card>
  );
};

ProductInfo.propTypes = {
  className: PropTypes.string,
  obtainRate:PropTypes.object
};

export default ProductInfo;

/*
        <div className={classes.progress}>
          <Typography variant="body1">Profile Completeness: 70%</Typography>
          <LinearProgress
            value={70}
            variant="determinate"
          />
        </div>

        <Button
          className={classes.uploadButton}
          color="primary"
          variant="text"
        >
          Upload picture
        </Button>
        <Button variant="text">Remove picture</Button>
        */
