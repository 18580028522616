import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Divider,
  TextField,
  Button,
  Modal 
} from '@material-ui/core';
import {domainUrl} from '../../../../constants';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import { HttpPostServiceContextConsumer, HttpGetServiceContextConsumer } from '../../../../contexts/HttpServiceContext';
import { SimpleModal } from '../../../../components/SimpleModal';
// import mockData from '../../data';
 
const useStyles = makeStyles(theme => ({
  root: {},
  imageContainer: {
    height: 64,
    width: 64,
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '100%'
  },
  statsItem: {
    display: 'flex',
    alignItems: 'center'
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const SetProduct = props => {
  console.log(props)
  const { className, postToServer,  ...rest } = props; 
  const [products, setProduct] = useState([]);
  const classes = useStyles();
  const [rate, setRate] = useState(0);
  const [responseMessage,setResponseMessage]= useState('');
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
     
  const handleChange = event => {
    setRate(parseFloat(event.target.value));
  };

  
  const handleResponse =(data)=>{
    
    if(data.data){
      setResponseMessage('Edit Rate Successfully');
      setOpen(true);
      
    }
    else{
      
      setResponseMessage('Edit Rate Failed');
      setOpen(true);
    }
  }

  const handleRateResponse =(data)=>{
    setProduct([data.data])
  }

  useEffect(() => { 
    handleGetRate();
},[]);

  const handleSubmitRate = event => {
     const url = domainUrl + 'Products/EditProductRate';
     var auth = localStorage.getItem('authorization');
     var query = {  
      "ProductName": "MYR/IDR",
      "Rate": rate , 
    }

    var query2 = JSON.stringify(query);
    console.log(auth)
    props.postToServerWithHeader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleResponse );  
  };

   
  const handleClose = () => {
    setOpen(false);
  };
  const handleGetRate = event => {
    const url = domainUrl + 'Products/GetProductRate';
    var auth = localStorage.getItem('authorization');
    var query = {  
     "ProductName": "MYR/IDR", 
   }

   var query2 = JSON.stringify(query);
   console.log(auth)
   props.postToServerWithHeader(url,query2, {'Content-Type':'application/json', 'Authorization': auth},handleRateResponse );  
 };
  return (
    <Grid
    container
    spacing={3}
  >
    {products.map(product => (
      <Grid
        item
        key={product.id}
        lg={4}
        md={6}
        xs={12}
      >
       
        <Card
        {...rest}
        className={clsx(classes.root, className)}
        >
        <CardContent>
            <Typography
            align="center"
            gutterBottom
            variant="h4"
            >
            {product.name}
            </Typography>
            <Typography
            align="center"
            variant="body1"
            > 
            </Typography>
            <br/>
        <Divider />
            <br/>
            <Typography
            align="center"
            variant="body1"
            >
            {product.current_rate} <br/>
            {product.modified}
            </Typography>
            
            <Typography
            align="center"
            variant="body1"
            > <br/>
            (New RATE) {rate}
            </Typography>
            <TextField
                    fullWidth
                    helperText="New Top Rate"
                    label="Set rate"
                    margin="dense"
                    name="rate"
                    onChange={handleChange}
                    required
                    value={rate}
                    variant="outlined"
                />
        </CardContent>
        <Divider />
        <CardActions>
            
            <Grid
            container
            justify="space-between"
            >
            <Grid
                className={classes.statsItem}
                item
            >
                <Button onClick={handleSubmitRate} color='secondary'>Set Rate</Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div style={modalStyle} className={classes.paper}>
                <h2 id="simple-modal-title">{'Edit Rate'}</h2>
                <p id="simple-modal-description">
                  {responseMessage}
                </p> 
              </div>
              </Modal>
            </Grid>
            </Grid>
        </CardActions>
        </Card>
      </Grid>
    ))}

        
  </Grid>
  
  );
};
SetProduct.propTypes = {
  className: PropTypes.string, 
};


const HocSetProduct = (props) => {  
   return (
     
 <HttpPostServiceContextConsumer> 
     {({ httpPostContext,
         setHttpPostContext,
         postToServerWithHeader  })=>
     (
       <React.Fragment> 
         <SetProduct {...props} postToServerWithHeader={postToServerWithHeader}  sethttppostcontext={setHttpPostContext} httppostcontext={httpPostContext}></SetProduct>
        </React.Fragment>
     )}
 </HttpPostServiceContextConsumer>
   );
 }
 
 
 HocSetProduct.propTypes = {
  className: PropTypes.string,
  postToServer: PropTypes.func,
  sethttppostcontext:  PropTypes.func,
  httppostcontext:  PropTypes.any,
};
 export default HocSetProduct;
