/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';

const ExpansionPanel = withStyles({
  root: {
    border: '0px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: '0px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    display:'flex',
    minHeight: 56,

    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
      minWidth:120
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props; 
  
const uType = localStorage.getItem('type');
  const [expanded, setExpanded] = React.useState('');

  const classes = useStyles();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {pages.map(page => (
       (page.title === 'Order') ? 
         uType == '2' ? <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')} key={'panel1'} >
        <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
         <div className={classes.icon} style={{ display: 'flex',float:'left' }}>{page.icon}</div>
         <Typography>
           {page.title}
           </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{paddingTop:0,marginTop:0}}> 
          <ListItem
          className={classes.item}
          disableGutters
          key={pages.indexOf(page)+'_placeorder'} 
          style={{minWidth:120}}
        >
            <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={page.href}
                
              >
                <div className={classes.icon} style={{ display: 'flex',float:'left' }}>{page.icon}</div>
                <Typography>
                
                {'Place Order'}
              </Typography>
            </Button>
        </ListItem>
            
          
          
        </ExpansionPanelDetails>
        
        <ExpansionPanelDetails  style={{paddingTop:0,marginTop:0}}> 
          <ListItem
          className={classes.item}
          disableGutters
          key={pages.indexOf(page)+'_orderlist'}
          style={{paddingTop:0,marginTop:0}}
        >
            <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                style={{paddingTop:0,marginTop:0}}
                to={'/orderlist'}
              >
                <div className={classes.icon} style={{ display: 'flex',float:'left' }}>{page.icon}</div>
                <Typography>
                
                {'Order List'}
              </Typography>
            </Button> 
        </ListItem>
            
        </ExpansionPanelDetails>
      </ExpansionPanel>
      : 
      
      uType == '0' ? <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')} key={'panel1'} >
      <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
       <div className={classes.icon} style={{ display: 'flex',float:'left' }}>{page.icon}</div>
       <Typography>
         {page.title}
         </Typography>
      </ExpansionPanelSummary> 
      
      <ExpansionPanelDetails  style={{paddingTop:0,marginTop:0}}> 
        <ListItem
        className={classes.item}
        disableGutters
        key={pages.indexOf(page)+'_orderlist'}
        style={{paddingTop:0,marginTop:0}}
      >
          <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              style={{paddingTop:0,marginTop:0}}
              to={'/tellerorderlist'}
            >
              <div className={classes.icon} style={{ display: 'flex',float:'left' }}>{page.icon}</div>
              <Typography>
              
              {'Teller Order List'}
            </Typography>
          </Button> 
      </ListItem>
          
      </ExpansionPanelDetails>
    </ExpansionPanel>
       :null
       :
       
        (page.title === 'Products')? 
        <ExpansionPanel square expanded={expanded === 'panel2'}  key={'panel2'}  onChange={handleChange('panel2')}>
        <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className={classes.icon} style={{ display: 'flex',float:'left' }}>{page.icon}</div>
        <Typography>{page.title}</Typography>
        </ExpansionPanelSummary>
        {(uType == '1')?<ExpansionPanelDetails style={{paddingTop:0,marginTop:0}}> 
          <ListItem
          className={classes.item}
          disableGutters
          key={pages.indexOf(page)+'_product'} 
          style={{minWidth:120}}
        >
            <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={page.href}
                
              >
                <div className={classes.icon} style={{ display: 'flex',float:'left' }}>{page.icon}</div>
                <Typography>
                
                {'The Product'}
              </Typography>
            </Button>
        </ListItem>
            
          
          
        </ExpansionPanelDetails>
        : null }
        {(uType == '1' || uType == '0')?<ExpansionPanelDetails  style={{paddingTop:0,marginTop:0}}> 
          <ListItem
          className={classes.item}
          disableGutters
          key={pages.indexOf(page)+'_setproductrate'}
          style={{paddingTop:0,marginTop:0}}
        >
            <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                style={{paddingTop:0,marginTop:0}}
                to={'/setproduct'}
              >
                <div className={classes.icon} style={{ display: 'flex',float:'left' }}>{page.icon}</div>
                <Typography>
                
                {'Set Product Rate'}
              </Typography>
            </Button> 
        </ListItem>
            
        </ExpansionPanelDetails>
        : <ExpansionPanelDetails  style={{paddingTop:0,marginTop:0}}> 
        <ListItem
        className={classes.item}
        disableGutters
        key={pages.indexOf(page)+'_setdownlinerate'}
        style={{paddingTop:0,marginTop:0}}
      >
          <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              style={{paddingTop:0,marginTop:0}}
              to={'/setrate'}
            >
              <div className={classes.icon} style={{ display: 'flex',float:'left' }}>{page.icon}</div>
              <Typography>
              
              {'Set Downline Rate'}
            </Typography>
          </Button> 
      </ListItem>
          
      </ExpansionPanelDetails>}
       
      </ExpansionPanel>
          :
          (page.title === 'Users' && uType !== '0')? 
            <ExpansionPanel square expanded={expanded === 'panel3'} key={'panel3'} onChange={handleChange('panel3')}>
              <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
              <div className={classes.icon} style={{ display: 'flex',float:'left' }}>{page.icon}</div>
               <Typography>{page.title}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{paddingTop:0,marginTop:0}}> 
                <ListItem
                className={classes.item}
                disableGutters
                key={pages.indexOf(page)+'_userlist'} 
                style={{minWidth:120}}
              >
                  <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to={'/users/list'}
                      
                    >
                       <div className={classes.icon} style={{ display: 'flex',float:'left' }}>{page.icon}</div>
                       <Typography>
                     
                      {'User List'}
                    </Typography>
                  </Button>
              </ListItem>
                  
                
                
              </ExpansionPanelDetails>
            
              <ExpansionPanelDetails  style={{paddingTop:0,marginTop:0}}> 
                
              <ListItem
                className={classes.item}
                disableGutters
                key={pages.indexOf(page)+'_usertree'}
                style={{paddingTop:0,marginTop:0}}
              >
                  <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      style={{paddingTop:0,marginTop:0}}
                      to={'/users/tree'}
                    >
                      <div className={classes.icon} style={{ display: 'flex',float:'left' }}>{page.icon}</div>
                      <Typography>
                      
                      {'User Tree'}
                    </Typography>
                  </Button> 
              </ListItem>
                  
              </ExpansionPanelDetails>
              
              <ExpansionPanelDetails style={{paddingTop:0,marginTop:0}}> 
                <ListItem
                className={classes.item}
                disableGutters
                key={pages.indexOf(page)+'_usernetworth'} 
                style={{minWidth:120}}
              >
                  <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to={'/users/usernetworth'}
                      
                    >
                       <div className={classes.icon} style={{ display: 'flex',float:'left' }}>{page.icon}</div>
                       <Typography>
                     
                      {'User Networth'}
                    </Typography>
                  </Button>
              </ListItem>
                  
                
                
              </ExpansionPanelDetails>

            </ExpansionPanel>
            :
            <ListItem
            className={classes.item}
            disableGutters
            key={pages.indexOf(page)+'_router'}
            >
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
            >
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          </ListItem>
      
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
